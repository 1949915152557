@charset "UTF-8";
/* Container */
/* UI */
@font-face {
  font-family: "decimaNova";
  src: url(ac31ea720255e3b63379d946864fc82b.woff2) format("woff2"), url(e2852cbbdc20840611cc1fb79cdd867f.woff) format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "decimaNova";
  src: url(3e9ada6ea36ada3f0a3598367fd8f375.woff2) format("woff2"), url(a690061af6bbbbe2332c0c15493aa639.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "decimaNova";
  src: url(7604a37bff6943751f54ef3c9f4254cb.woff2) format("woff2"), url(165c52711ed72ab1eab6639c6f59751f.woff) format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "decimaNovaPro";
  src: url(7a9612d133fbae5b0b06a29697345558.woff2) format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "decimaNovaPro";
  src: url(269dd96e9bd564088bfda0b927a487ef.woff2) format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "decimaNovaPro";
  src: url(269dd96e9bd564088bfda0b927a487ef.woff2) format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "decimaNovaPro";
  src: url(269dd96e9bd564088bfda0b927a487ef.woff2) format("woff2");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  outline: none;
}

*[role=button] {
  cursor: pointer;
}

*::-moz-selection {
  color: #fff;
  background-color: #ff4b1e;
}

*::selection {
  color: #fff;
  background-color: #ff4b1e;
}

body {
  min-width: 320px;
  margin: 0 auto;
  font-size: 16px;
  font-family: "decimaNova", sans-serif;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

sup {
  font-size: 60%;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
}

p {
  margin: 0;
}

button {
  cursor: pointer;
}

textarea,
input[type=text],
input[type=button],
input[type=submit] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

textarea,
select,
input[type=text],
input[type=button],
input[type=submit] {
  border-radius: 0;
}

*:disabled {
  pointer-events: none;
  opacity: 0.5;
}

table {
  border-collapse: collapse;
}

/*!
 * Bootstrap Grid v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 1200px) {
  .container {
    max-width: 962px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 8.3333333333%;
          flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 16.6666666667%;
          flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 33.3333333333%;
          flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 41.6666666667%;
          flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 58.3333333333%;
          flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 66.6666666667%;
          flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
          flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 83.3333333333%;
          flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 91.6666666667%;
          flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1;
}

.order-last {
  -webkit-box-ordinal-group: 14;
      -ms-flex-order: 13;
          order: 13;
}

.order-0 {
  -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
          order: 0;
}

.order-1 {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
}

.order-2 {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}

.order-3 {
  -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3;
}

.order-4 {
  -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
          order: 4;
}

.order-5 {
  -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
          order: 5;
}

.order-6 {
  -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
          order: 6;
}

.order-7 {
  -webkit-box-ordinal-group: 8;
      -ms-flex-order: 7;
          order: 7;
}

.order-8 {
  -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
          order: 8;
}

.order-9 {
  -webkit-box-ordinal-group: 10;
      -ms-flex-order: 9;
          order: 9;
}

.order-10 {
  -webkit-box-ordinal-group: 11;
      -ms-flex-order: 10;
          order: 10;
}

.order-11 {
  -webkit-box-ordinal-group: 12;
      -ms-flex-order: 11;
          order: 11;
}

.order-12 {
  -webkit-box-ordinal-group: 13;
      -ms-flex-order: 12;
          order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.3333333333%;
            flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.6666666667%;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.3333333333%;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.6666666667%;
            flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.3333333333%;
            flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.6666666667%;
            flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.3333333333%;
            flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.6666666667%;
            flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }

  .order-sm-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13;
  }

  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0;
  }

  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }

  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }

  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }

  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }

  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }

  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }

  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }

  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }

  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9;
  }

  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
  }

  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11;
  }

  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.3333333333%;
            flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.6666666667%;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.3333333333%;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.6666666667%;
            flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.3333333333%;
            flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.6666666667%;
            flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.3333333333%;
            flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.6666666667%;
            flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }

  .order-md-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13;
  }

  .order-md-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0;
  }

  .order-md-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }

  .order-md-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }

  .order-md-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }

  .order-md-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }

  .order-md-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }

  .order-md-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }

  .order-md-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }

  .order-md-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }

  .order-md-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9;
  }

  .order-md-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
  }

  .order-md-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11;
  }

  .order-md-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.3333333333%;
            flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.6666666667%;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.3333333333%;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.6666666667%;
            flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.3333333333%;
            flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.6666666667%;
            flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.3333333333%;
            flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.6666666667%;
            flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }

  .order-lg-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13;
  }

  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0;
  }

  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }

  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }

  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }

  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }

  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }

  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }

  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }

  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }

  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9;
  }

  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
  }

  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11;
  }

  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.3333333333%;
            flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.6666666667%;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.3333333333%;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.6666666667%;
            flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.3333333333%;
            flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.6666666667%;
            flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.3333333333%;
            flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.6666666667%;
            flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }

  .order-xl-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13;
  }

  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0;
  }

  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }

  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }

  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }

  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }

  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }

  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }

  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }

  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }

  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9;
  }

  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
  }

  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11;
  }

  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
          flex-direction: row !important;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
          flex-direction: column !important;
}

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
          flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
          flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -webkit-box-flex: 1 !important;
      -ms-flex: 1 1 auto !important;
          flex: 1 1 auto !important;
}

.flex-grow-0 {
  -webkit-box-flex: 0 !important;
      -ms-flex-positive: 0 !important;
          flex-grow: 0 !important;
}

.flex-grow-1 {
  -webkit-box-flex: 1 !important;
      -ms-flex-positive: 1 !important;
          flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important;
}

.justify-content-start {
  -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
          justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
          justify-content: flex-end !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
          justify-content: center !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
          justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
}

.align-items-start {
  -webkit-box-align: start !important;
      -ms-flex-align: start !important;
          align-items: flex-start !important;
}

.align-items-end {
  -webkit-box-align: end !important;
      -ms-flex-align: end !important;
          align-items: flex-end !important;
}

.align-items-center {
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important;
}

.align-items-baseline {
  -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
          align-items: baseline !important;
}

.align-items-stretch {
  -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
          align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
      align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
      align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
      align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
      align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
      align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
      align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
      align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
      align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
      align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
      align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
      align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }

  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }

  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }

  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }

  .align-items-sm-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }

  .align-items-sm-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }

  .align-items-sm-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }

  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }

  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }

  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }

  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }

  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }

  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }

  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }

  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }

  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important;
  }

  .align-self-sm-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }

  .align-self-sm-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }

  .align-self-sm-center {
    -ms-flex-item-align: center !important;
        align-self: center !important;
  }

  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }

  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }

  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }

  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }

  .justify-content-md-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }

  .justify-content-md-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }

  .justify-content-md-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }

  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }

  .align-items-md-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }

  .align-items-md-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }

  .align-items-md-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }

  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }

  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }

  .align-content-md-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }

  .align-content-md-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }

  .align-content-md-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }

  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }

  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }

  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }

  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important;
  }

  .align-self-md-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }

  .align-self-md-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }

  .align-self-md-center {
    -ms-flex-item-align: center !important;
        align-self: center !important;
  }

  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }

  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }

  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }

  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }

  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }

  .align-items-lg-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }

  .align-items-lg-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }

  .align-items-lg-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }

  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }

  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }

  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }

  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }

  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }

  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }

  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }

  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }

  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important;
  }

  .align-self-lg-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }

  .align-self-lg-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }

  .align-self-lg-center {
    -ms-flex-item-align: center !important;
        align-self: center !important;
  }

  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }

  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }

  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }

  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }

  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }

  .align-items-xl-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }

  .align-items-xl-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }

  .align-items-xl-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }

  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }

  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }

  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }

  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }

  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }

  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }

  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }

  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }

  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important;
  }

  .align-self-xl-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }

  .align-self-xl-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }

  .align-self-xl-center {
    -ms-flex-item-align: center !important;
        align-self: center !important;
  }

  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }

  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
  }
}
.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0;
}

.fade {
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    -webkit-transition: none;
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    -webkit-transition: none;
    transition: none;
  }
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    -webkit-transition: none;
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #cdcdcd;
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
.mdl-button {
  background: transparent;
  border: none;
  border-radius: 2px;
  color: rgb(0,0,0);
  position: relative;
  height: 36px;
  margin: 0;
  min-width: 64px;
  padding: 0 16px;
  display: inline-block;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 0;
  overflow: hidden;
  will-change: box-shadow;
  -webkit-transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1), -webkit-box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1);
  transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1), -webkit-box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1);
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1), -webkit-box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1);
  outline: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  line-height: 36px;
  vertical-align: middle;
}
.mdl-button::-moz-focus-inner {
  border: 0;
}
.mdl-button:hover {
  background-color: rgba(158,158,158, 0.20);
}
.mdl-button:focus:not(:active) {
  background-color: rgba(0,0,0, 0.12);
}
.mdl-button:active {
  background-color: rgba(158,158,158, 0.40);
}
.mdl-button.mdl-button--colored {
  color: rgb(63,81,181);
}
.mdl-button.mdl-button--colored:focus:not(:active) {
  background-color: rgba(0,0,0, 0.12);
}

input.mdl-button[type=submit] {
  -webkit-appearance: none;
}

.mdl-button--raised {
  background: rgba(158,158,158, 0.20);
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.mdl-button--raised:active {
  -webkit-box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
          box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  background-color: rgba(158,158,158, 0.40);
}
.mdl-button--raised:focus:not(:active) {
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.18), 0 8px 16px rgba(0, 0, 0, 0.36);
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.18), 0 8px 16px rgba(0, 0, 0, 0.36);
  background-color: rgba(158,158,158, 0.40);
}
.mdl-button--raised.mdl-button--colored {
  background: rgb(63,81,181);
  color: rgb(255,255,255);
}
.mdl-button--raised.mdl-button--colored:hover {
  background-color: rgb(63,81,181);
}
.mdl-button--raised.mdl-button--colored:active {
  background-color: rgb(63,81,181);
}
.mdl-button--raised.mdl-button--colored:focus:not(:active) {
  background-color: rgb(63,81,181);
}
.mdl-button--raised.mdl-button--colored .mdl-ripple {
  background: rgb(255,255,255);
}

.mdl-button--fab {
  border-radius: 50%;
  font-size: 24px;
  height: 56px;
  margin: auto;
  min-width: 56px;
  width: 56px;
  padding: 0;
  overflow: hidden;
  background: rgba(158,158,158, 0.20);
  -webkit-box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
          box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
  position: relative;
  line-height: normal;
}
.mdl-button--fab .material-icons {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-12px, -12px);
          transform: translate(-12px, -12px);
  line-height: 24px;
  width: 24px;
}
.mdl-button--fab.mdl-button--mini-fab {
  height: 40px;
  min-width: 40px;
  width: 40px;
}
.mdl-button--fab .mdl-button__ripple-container {
  border-radius: 50%;
  -webkit-mask-image: -webkit-radial-gradient(circle, white, black);
}
.mdl-button--fab:active {
  -webkit-box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
          box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  background-color: rgba(158,158,158, 0.40);
}
.mdl-button--fab:focus:not(:active) {
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.18), 0 8px 16px rgba(0, 0, 0, 0.36);
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.18), 0 8px 16px rgba(0, 0, 0, 0.36);
  background-color: rgba(158,158,158, 0.40);
}
.mdl-button--fab.mdl-button--colored {
  background: rgb(255,64,129);
  color: rgb(255,255,255);
}
.mdl-button--fab.mdl-button--colored:hover {
  background-color: rgb(255,64,129);
}
.mdl-button--fab.mdl-button--colored:focus:not(:active) {
  background-color: rgb(255,64,129);
}
.mdl-button--fab.mdl-button--colored:active {
  background-color: rgb(255,64,129);
}
.mdl-button--fab.mdl-button--colored .mdl-ripple {
  background: rgb(255,255,255);
}

.mdl-button--icon {
  border-radius: 50%;
  font-size: 24px;
  height: 32px;
  margin-left: 0;
  margin-right: 0;
  min-width: 32px;
  width: 32px;
  padding: 0;
  overflow: hidden;
  color: inherit;
  line-height: normal;
}
.mdl-button--icon .material-icons {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-12px, -12px);
          transform: translate(-12px, -12px);
  line-height: 24px;
  width: 24px;
}
.mdl-button--icon.mdl-button--mini-icon {
  height: 24px;
  min-width: 24px;
  width: 24px;
}
.mdl-button--icon.mdl-button--mini-icon .material-icons {
  top: 0px;
  left: 0px;
}
.mdl-button--icon .mdl-button__ripple-container {
  border-radius: 50%;
  -webkit-mask-image: -webkit-radial-gradient(circle, white, black);
}

.mdl-button__ripple-container {
  display: block;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 0;
  overflow: hidden;
}
.mdl-button[disabled] .mdl-button__ripple-container .mdl-ripple, .mdl-button.mdl-button--disabled .mdl-button__ripple-container .mdl-ripple {
  background-color: transparent;
}

.mdl-button--primary.mdl-button--primary {
  color: rgb(63,81,181);
}
.mdl-button--primary.mdl-button--primary .mdl-ripple {
  background: rgb(255,255,255);
}
.mdl-button--primary.mdl-button--primary.mdl-button--raised, .mdl-button--primary.mdl-button--primary.mdl-button--fab {
  color: rgb(255,255,255);
  background-color: rgb(63,81,181);
}

.mdl-button--accent.mdl-button--accent {
  color: rgb(255,64,129);
}
.mdl-button--accent.mdl-button--accent .mdl-ripple {
  background: rgb(255,255,255);
}
.mdl-button--accent.mdl-button--accent.mdl-button--raised, .mdl-button--accent.mdl-button--accent.mdl-button--fab {
  color: rgb(255,255,255);
  background-color: rgb(255,64,129);
}

.mdl-button[disabled][disabled], .mdl-button.mdl-button--disabled.mdl-button--disabled {
  color: rgba(0,0,0, 0.26);
  cursor: default;
  background-color: transparent;
}
.mdl-button--fab[disabled][disabled], .mdl-button--fab.mdl-button--disabled.mdl-button--disabled {
  background-color: rgba(0,0,0, 0.12);
  color: rgba(0,0,0, 0.26);
}
.mdl-button--raised[disabled][disabled], .mdl-button--raised.mdl-button--disabled.mdl-button--disabled {
  background-color: rgba(0,0,0, 0.12);
  color: rgba(0,0,0, 0.26);
  -webkit-box-shadow: none;
          box-shadow: none;
}
.mdl-button--colored[disabled][disabled], .mdl-button--colored.mdl-button--disabled.mdl-button--disabled {
  color: rgba(0,0,0, 0.26);
}

.mdl-button .material-icons {
  vertical-align: middle;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
.mdl-textfield {
  position: relative;
  font-size: 16px;
  display: inline-block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 300px;
  max-width: 100%;
  margin: 0;
  padding: 20px 0;
}
.mdl-textfield .mdl-button {
  position: absolute;
  bottom: 20px;
}

.mdl-textfield--align-right {
  text-align: right;
}

.mdl-textfield--full-width {
  width: 100%;
}

.mdl-textfield--expandable {
  min-width: 32px;
  width: auto;
  min-height: 32px;
}
.mdl-textfield--expandable .mdl-button--icon {
  top: 16px;
}

.mdl-textfield__input {
  border: none;
  border-bottom: 1px solid rgba(0,0,0, 0.12);
  display: block;
  font-size: 16px;
  font-family: "Helvetica", "Arial", sans-serif;
  margin: 0;
  padding: 4px 0;
  width: 100%;
  background: none;
  text-align: left;
  color: inherit;
}
.mdl-textfield__input[type=number] {
  -moz-appearance: textfield;
}
.mdl-textfield__input[type=number]::-webkit-inner-spin-button, .mdl-textfield__input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.mdl-textfield.is-focused .mdl-textfield__input {
  outline: none;
}
.mdl-textfield.is-invalid .mdl-textfield__input {
  border-color: rgb(213,0,0);
  -webkit-box-shadow: none;
          box-shadow: none;
}
fieldset[disabled] .mdl-textfield .mdl-textfield__input, .mdl-textfield.is-disabled .mdl-textfield__input {
  background-color: transparent;
  border-bottom: 1px dotted rgba(0,0,0, 0.12);
  color: rgba(0,0,0, 0.26);
}

.mdl-textfield textarea.mdl-textfield__input {
  display: block;
}

.mdl-textfield__label {
  bottom: 0;
  color: rgba(0,0,0, 0.26);
  font-size: 16px;
  left: 0;
  right: 0;
  pointer-events: none;
  position: absolute;
  display: block;
  top: 24px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
}
.mdl-textfield.is-dirty .mdl-textfield__label, .mdl-textfield.has-placeholder .mdl-textfield__label {
  visibility: hidden;
}
.mdl-textfield--floating-label .mdl-textfield__label {
  -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.mdl-textfield--floating-label.has-placeholder .mdl-textfield__label {
  -webkit-transition: none;
  transition: none;
}
fieldset[disabled] .mdl-textfield .mdl-textfield__label, .mdl-textfield.is-disabled.is-disabled .mdl-textfield__label {
  color: rgba(0,0,0, 0.26);
}
.mdl-textfield--floating-label.is-focused .mdl-textfield__label, .mdl-textfield--floating-label.is-dirty .mdl-textfield__label, .mdl-textfield--floating-label.has-placeholder .mdl-textfield__label {
  color: rgb(63,81,181);
  font-size: 12px;
  top: 4px;
  visibility: visible;
}
.mdl-textfield--floating-label.is-focused .mdl-textfield__expandable-holder .mdl-textfield__label, .mdl-textfield--floating-label.is-dirty .mdl-textfield__expandable-holder .mdl-textfield__label, .mdl-textfield--floating-label.has-placeholder .mdl-textfield__expandable-holder .mdl-textfield__label {
  top: -16px;
}
.mdl-textfield--floating-label.is-invalid .mdl-textfield__label {
  color: rgb(213,0,0);
  font-size: 12px;
}
.mdl-textfield__label:after {
  background-color: rgb(63,81,181);
  bottom: 20px;
  content: "";
  height: 2px;
  left: 45%;
  position: absolute;
  -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  visibility: hidden;
  width: 10px;
}
.mdl-textfield.is-focused .mdl-textfield__label:after {
  left: 0;
  visibility: visible;
  width: 100%;
}
.mdl-textfield.is-invalid .mdl-textfield__label:after {
  background-color: rgb(213,0,0);
}

.mdl-textfield__error {
  color: rgb(213,0,0);
  position: absolute;
  font-size: 12px;
  margin-top: 3px;
  visibility: hidden;
  display: block;
}
.mdl-textfield.is-invalid .mdl-textfield__error {
  visibility: visible;
}

.mdl-textfield__expandable-holder {
  display: inline-block;
  position: relative;
  margin-left: 32px;
  -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  display: inline-block;
  max-width: 0.1px;
}
.mdl-textfield.is-focused .mdl-textfield__expandable-holder, .mdl-textfield.is-dirty .mdl-textfield__expandable-holder {
  max-width: 600px;
}
.mdl-textfield__expandable-holder .mdl-textfield__label:after {
  bottom: 0;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
.mdl-switch {
  position: relative;
  z-index: 1;
  vertical-align: middle;
  display: inline-block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  height: 24px;
  margin: 0;
  padding: 0;
  overflow: visible;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.mdl-switch.is-upgraded {
  padding-left: 28px;
}

.mdl-switch__input {
  line-height: 24px;
}
.mdl-switch.is-upgraded .mdl-switch__input {
  position: absolute;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
  -ms-appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
}

.mdl-switch__track {
  background: rgba(0,0,0, 0.26);
  position: absolute;
  left: 0;
  top: 5px;
  height: 14px;
  width: 36px;
  border-radius: 14px;
  cursor: pointer;
}
.mdl-switch.is-checked .mdl-switch__track {
  background: rgba(63,81,181, 0.5);
}
.mdl-switch__track fieldset[disabled] .mdl-switch, .mdl-switch.is-disabled .mdl-switch__track {
  background: rgba(0,0,0, 0.12);
  cursor: auto;
}

.mdl-switch__thumb {
  background: rgb(250,250,250);
  position: absolute;
  left: 0;
  top: 2px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: pointer;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  -webkit-transition-duration: 0.28s;
          transition-duration: 0.28s;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-property: left;
  transition-property: left;
}
.mdl-switch.is-checked .mdl-switch__thumb {
  background: rgb(63,81,181);
  left: 16px;
  -webkit-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
          box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
}
.mdl-switch__thumb fieldset[disabled] .mdl-switch, .mdl-switch.is-disabled .mdl-switch__thumb {
  background: rgb(189,189,189);
  cursor: auto;
}

.mdl-switch__focus-helper {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-4px, -4px);
          transform: translate(-4px, -4px);
  display: inline-block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: transparent;
}
.mdl-switch.is-focused .mdl-switch__focus-helper {
  -webkit-box-shadow: 0 0 0px 20px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 0px 20px rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.1);
}
.mdl-switch.is-focused.is-checked .mdl-switch__focus-helper {
  -webkit-box-shadow: 0 0 0px 20px rgba(63,81,181, 0.26);
          box-shadow: 0 0 0px 20px rgba(63,81,181, 0.26);
  background-color: rgba(63,81,181, 0.26);
}

.mdl-switch__label {
  position: relative;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  left: 24px;
}
.mdl-switch__label fieldset[disabled] .mdl-switch, .mdl-switch.is-disabled .mdl-switch__label {
  color: rgb(189,189,189);
  cursor: auto;
}

.mdl-switch__ripple-container {
  position: absolute;
  z-index: 2;
  top: -12px;
  left: -14px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(circle, white, black);
  -webkit-transition-duration: 0.4s;
          transition-duration: 0.4s;
  -webkit-transition-timing-function: step-end;
          transition-timing-function: step-end;
  -webkit-transition-property: left;
  transition-property: left;
}
.mdl-switch__ripple-container .mdl-ripple {
  background: rgb(63,81,181);
}
.mdl-switch__ripple-container fieldset[disabled] .mdl-switch, .mdl-switch.is-disabled .mdl-switch__ripple-container {
  cursor: auto;
}
fieldset[disabled] .mdl-switch .mdl-switch__ripple-container .mdl-ripple, .mdl-switch.is-disabled .mdl-switch__ripple-container .mdl-ripple {
  background: transparent;
}
.mdl-switch.is-checked .mdl-switch__ripple-container {
  left: 2px;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
.mdl-ripple {
  background: rgb(0,0,0);
  border-radius: 50%;
  height: 50px;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 50px;
  overflow: hidden;
}
.mdl-ripple.is-animating {
  -webkit-transition: width 0.3s cubic-bezier(0, 0, 0.2, 1), height 0.3s cubic-bezier(0, 0, 0.2, 1), opacity 0.6s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.3s cubic-bezier(0, 0, 0.2, 1);
  transition: width 0.3s cubic-bezier(0, 0, 0.2, 1), height 0.3s cubic-bezier(0, 0, 0.2, 1), opacity 0.6s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.3s cubic-bezier(0, 0, 0.2, 1);
  transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1), width 0.3s cubic-bezier(0, 0, 0.2, 1), height 0.3s cubic-bezier(0, 0, 0.2, 1), opacity 0.6s cubic-bezier(0, 0, 0.2, 1);
  transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1), width 0.3s cubic-bezier(0, 0, 0.2, 1), height 0.3s cubic-bezier(0, 0, 0.2, 1), opacity 0.6s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.mdl-ripple.is-visible {
  opacity: 0.3;
}

.index {
  color: #6610f2;
}

.mainpage__subtitle {
  max-width: 945px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
}
.mainpage__subtitle b {
  font-weight: bold;
}

.download_block {
  margin-bottom: 20px;
  text-align: center;
}
.download_block__variant {
  display: none;
}
.download_block__variant.active {
  display: block;
}
@media (min-width: 768px) {
  .download_block__title {
    margin-bottom: 20px;
  }
}
.download_block__list_button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  list-style: none;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.download_block__list_button .button {
  margin: 0;
}
.download_block__button_wrapper {
  display: block;
  margin: 0 5px 40px;
}
.download_block__subtitle {
  margin-bottom: 15px;
  font-size: 24px;
  font-weight: 500;
  color: #323343;
}
.download_block__text {
  margin-bottom: 20px;
  font-size: 18px;
  color: #557;
}
@media (min-width: 992px) {
  .download_block__text {
    margin-bottom: 40px;
  }
}
@media (min-width: 992px) {
  .download_block__button {
    padding: 16px 30px;
  }
}
.download_block__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  margin: 0 auto;
  list-style: none;
  max-width: 560px;
}
.download_block__item {
  margin-bottom: 10px;
  padding: 0 20px;
}
@media (min-width: 768px) {
  .download_block__item {
    width: 50%;
    margin-bottom: 25px;
  }
}
.download_block__link {
  font-size: 18px;
  color: #ff4213;
}
.download_block__link:hover .DownloadBlock__LinkText {
  border-bottom-color: transparent;
  cursor: pointer;
}
.download_block__link_icon {
  width: 22px;
  height: 22px;
  margin-right: 5px;
  vertical-align: top;
}
.download_block__link_text {
  border-bottom: 1px dotted #ff4213;
}
.download_block__link_text:hover {
  border-bottom: none;
}
.download_block__typography_text ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.error404 {
  background-image: url(0e6364cf315996bece753ac4c9927330.jpg);
  background-size: cover;
  background-position: 50% 50%;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-bottom: 40px;
  padding-top: 200px;
}
.error404__title {
  font-size: 160px;
  color: #fff;
  line-height: 0.75;
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
}
@media (max-width: 575.98px) {
  .error404__title {
    font-size: 80px;
  }
}
@media (min-width: 768px) {
  .error404__title {
    font-size: 257px;
  }
}
.error404__subtitle {
  font-size: 30px;
  color: #fff;
  margin-top: 15px;
  text-align: center;
}
@media (max-width: 575.98px) {
  .error404__subtitle {
    font-size: 25px;
  }
}
@media (min-width: 768px) {
  .error404__subtitle {
    font-size: 52px;
  }
}

.header404 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 40px;
  margin-bottom: 120px;
}
.header404 a {
  width: 145px;
  margin-top: 14px;
}

.page--help .nav_button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
@media (min-width: 768px) {
  .page--help .nav_button {
    padding-top: 60px;
    padding-bottom: 65px;
  }
}
.page--help .nav_button__link {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: #292929;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  margin-right: 15px;
  margin-top: 10px;
  white-space: nowrap;
  cursor: pointer;
  border-bottom: 1px dashed #323343;
  -webkit-transition: border-bottom-color 250ms;
  transition: border-bottom-color 250ms;
}
.page--help .nav_button__link_text {
  font-size: 20px;
}
.page--help .nav_button__link:last-child {
  margin-right: 0;
}
.page--help .nav_button__link:hover {
  border-bottom-color: transparent;
}
.page--help .nav_button__link--active {
  cursor: initial;
  border-bottom-color: transparent;
}
.page--help .nav_button__link--active .nav_button__link_text {
  color: #ef4115;
}
.page--help .nav_button__link svg, .page--help .nav_button__link img {
  width: auto;
  height: 15px;
  margin-right: 7px;
}

.thank_you {
  padding-top: 100px;
  padding-bottom: 30px;
}
.thank_you__title {
  color: #333;
  margin-bottom: 10px;
  padding-top: 20px;
  font-size: 25px;
  line-height: 25px;
  text-align: center;
  font-weight: 500;
}
.thank_you .thank_you__text {
  color: #557;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  padding-top: 10px;
  text-align: center;
}

.releases_download {
  margin-bottom: 25px;
}
@media (min-width: 768px) {
  .releases_download {
    margin-bottom: 50px;
  }
}
.releases_download .button {
  margin-left: auto;
  margin-right: auto;
}

.release_list {
  margin-bottom: 70px;
  list-style: none;
  text-align: left;
}
.release_list__item_row {
  min-height: 40px;
  border-bottom: 1px solid #ebebeb;
  padding: 20px 0;
}
.release_list__item_row:hover {
  background-color: #eae9e9;
  -webkit-transition: 300ms;
  transition: 300ms;
}
@media (min-width: 768px) {
  .release_list__date {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .release_list__info {
    text-align: right;
  }
}

.release_description {
  font-weight: 100;
  color: #557;
}
.release_description:not(:last-of-type) {
  margin-bottom: 16px;
}
.release_description__title {
  font-family: "decimaNova", sans-serif;
  font-weight: bold;
  color: #333;
  margin-top: 23px;
  margin-bottom: 8px;
  text-align: left;
}

.release_download {
  margin: auto;
}
.release_download .button {
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 25px;
}

.add_issue_plugin__header {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.add_issue_plugin__header .add_issue_plugin__button {
  font-weight: 100;
  font-size: 20px;
  padding: 17px 30px;
  margin: 0 30px;
}
@media (min-width: 768px) {
  .add_issue_plugin__header .add_issue_plugin__button {
    font-size: 24px;
    padding: 22px 70px;
  }
}
.add_issue_plugin__subtitle {
  text-align: center;
  font-size: 23px;
  font-weight: 600;
  margin-top: 30px;
}
@media (min-width: 768px) {
  .add_issue_plugin__subtitle {
    margin-top: 0;
  }
}
.add_issue_plugin__content {
  margin-top: 50px;
  border-top: 1px solid #dadada;
}
.add_issue_plugin__content_title {
  text-align: center;
}
.add_issue_plugin__content .tip_hint {
  font-size: 14px;
}
.add_issue_plugin__content .add_issue_plugin__instruction_download {
  text-align: center;
  margin-bottom: 30px;
}
.add_issue_plugin__content .add_issue_plugin__instruction_download .add_issue_plugin__button {
  font-weight: 100;
  font-size: 20px;
  padding: 17px 30px;
  margin: 0 30px;
}
@media (min-width: 768px) {
  .add_issue_plugin__content .add_issue_plugin__instruction_download .add_issue_plugin__button {
    font-size: 24px;
    padding: 22px 70px;
  }
}
.add_issue_plugin__instruction_title {
  text-align: center;
}
.add_issue_plugin__instruction .code {
  font-size: 14px;
  margin: 12px 0 0 0;
  display: block;
  background-color: #f1f1f1;
  border: 1px solid #e6e6e6;
  word-break: break-word;
}

.feedback__form {
  padding-bottom: 15px;
}
.feedback__button.button {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-width: 150px;
  min-height: 50px;
  margin: 15px auto;
  font-size: 18px;
}

.post .page_title {
  margin-bottom: 18px;
}
@media (max-width: 991.98px) {
  .post .page_title {
    margin-top: 10px;
    margin-bottom: 22px;
    line-height: 1;
  }
}
@media (max-width: 767.98px) {
  .post .page_title {
    line-height: 0.9;
    margin-bottom: 18px;
  }
}
.post__header {
  padding-bottom: 19px;
}
@media (min-width: 768px) {
  .post__header {
    padding-bottom: 23px;
  }
}
.post__category {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 28px;
  padding: 4px 10px;
  background-color: #ff4213;
}
.post__date {
  display: inline-block;
  margin-left: 10px;
  color: #bdbdbd;
  margin-top: 15px;
}
@media (min-width: 768px) {
  .post__date {
    margin-top: 0;
  }
}
.post__banner {
  margin-bottom: 18px;
}
@media (min-width: 768px) {
  .post__banner {
    margin-bottom: 40px;
    text-align: center;
  }
}
.post__banner img {
  -webkit-box-shadow: 0 2px 3px rgba(108, 108, 140, 0.75);
          box-shadow: 0 2px 3px rgba(108, 108, 140, 0.75);
}
.post__short_text {
  font-size: 20px;
  color: #001F2F;
  margin-bottom: 27px;
  line-height: 1.4;
}
@media (min-width: 768px) {
  .post__short_text {
    margin-bottom: 41px;
    font-size: 25px;
  }
}
@media (min-width: 1200px) {
  .post__short_text {
    margin-bottom: 60px;
    line-height: inherit;
  }
}
.post__info_wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.post__info_author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (min-width: 768px) {
  .post__info_author {
    gap: 20px;
    -ms-flex-wrap: unset;
        flex-wrap: unset;
  }
}
.post__info_author_photo {
  color: #4c4c4c;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
  -webkit-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
.post__info_author_photo:hover {
  color: #ff4b1e;
}
.post__info_author_photo img {
  width: 35px;
  height: 35px;
  min-width: 35px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (min-width: 768px) {
  .post__info_author_photo img {
    width: 50px;
    height: 50px;
  }
}
.post__info_author .social-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
}
@media (min-width: 768px) {
  .post__info_author .social-links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 10px;
  }
}
.post__info_author .social-links img {
  width: 20px;
}
@media (min-width: 768px) {
  .post__info_author .social-links img {
    width: 30px;
  }
}
.post__nav_wrap {
  position: relative;
}
@media (max-width: 991.98px) {
  .post__nav_wrap {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0;
  }
}
.post__nav_content {
  position: sticky;
  top: 120px;
}
.post__nav_content_headers {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .post__nav_content_headers {
    margin-bottom: 0;
    gap: 0;
  }
}
.post__nav_item {
  display: inline-block;
  font-weight: 100;
  font-size: 18px;
  line-height: 23px;
  color: #323343;
  background-color: transparent;
  padding: 0;
  border: none;
  border-bottom: 1px dashed #323343;
  cursor: pointer;
  margin-bottom: 10px;
  text-align: left;
  -webkit-transition: 150ms;
  transition: 150ms;
}
.post__nav_item:hover {
  color: #ff4b1e;
  border-bottom-color: transparent;
  -webkit-transition: 150ms;
  transition: 150ms;
}
@media (max-width: 991.98px) {
  .post__content {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
}
.post__content_bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-top: 1px solid #e6e6e6;
  padding-top: 15px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 40px;
}
.post__help.help {
  padding-bottom: 30px;
  padding-top: 30px;
  -webkit-box-shadow: 0 2px 5px rgba(108, 108, 140, 0.75);
          box-shadow: 0 2px 5px rgba(108, 108, 140, 0.75);
}
.post__help.help .section_title {
  margin-top: 0;
}
.post__help.help .help_col__button {
  margin-top: 20px;
}
@media (min-width: 768px) {
  .post__help.help .help_col__button {
    margin-top: 0;
  }
}
.post .faq_accordion a {
  text-decoration: none;
}
.post .faq_accordion a.collapsed h3:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transition: 150ms;
  transition: 150ms;
}
.post .faq_accordion a h3:after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  -webkit-transition: 150ms;
  transition: 150ms;
}
.post .faq_accordion__title {
  margin-top: 20px !important;
  font-size: 20px;
  line-height: 1.15;
}
.post__share_social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
}
.post__share_social_title {
  color: #323343;
  font-size: 18px;
}
.post__subscribe_wrap {
  -webkit-box-shadow: 0 2px 5px rgba(108, 108, 140, 0.75);
          box-shadow: 0 2px 5px rgba(108, 108, 140, 0.75);
  padding: 10px 20px;
  margin-bottom: 75px;
}
.post__subscribe_wrap .subscribe__row {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.post__subscribe_wrap .subscribe_submit {
  padding: 12px 0;
}
.post__subscribe_wrap .subscribe_submit .button {
  margin: 0 auto;
}
.post .faq {
  padding-bottom: 0;
}
.post .rating_wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.post .rating input {
  opacity: 0;
  position: absolute;
  float: right;
}
.post .rating label {
  float: right;
  color: #bdbdbd;
  font-size: 30px;
  text-decoration: none;
  -webkit-transition: color 0.4s;
  transition: color 0.4s;
}
.post .rating label:hover ~ label,
.post .rating input:focus ~ label,
.post .rating input:checked ~ label,
.post .rating label:hover {
  color: #ff4213;
  cursor: pointer;
}
.post .rating input:disabled ~ label {
  cursor: default;
  pointer-events: none;
}
.post .similar-articles {
  position: relative;
  padding-top: 50px;
  padding-bottom: 80px;
}
@media (min-width: 768px) {
  .post .similar-articles {
    padding-bottom: 40px;
  }
}
@media (min-width: 992px) {
  .post .similar-articles {
    padding-top: 0;
  }
}
.post .similar-articles .slick-track {
  margin-left: 0;
}
.post .similar-articles .slick-slide {
  padding: 0 15px;
}
.post .similar-articles .slick-arrow {
  top: 25px;
  z-index: 4;
  opacity: 0.3;
}
.post .similar-articles .slick-arrow:before {
  color: #999;
}
@media (min-width: 992px) {
  .post .similar-articles .slick-arrow {
    top: 60px;
  }
}
.post .similar-articles .slick-next {
  background-image: url(eaed32b32e718022ec2865cb4e317cd2.svg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
  width: 24px;
  height: 64px;
  right: -45px;
}
.post .similar-articles .slick-next:hover {
  opacity: 1;
  background-image: url(f02cbb304ee415bb835c0ee4acf62a1a.svg);
}
.post .similar-articles .slick-next::before {
  content: "";
}
.post .similar-articles .slick-prev {
  background-image: url(eaed32b32e718022ec2865cb4e317cd2.svg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  width: 24px;
  height: 64px;
  left: -45px;
}
.post .similar-articles .slick-prev:hover {
  opacity: 1;
  background-image: url(f02cbb304ee415bb835c0ee4acf62a1a.svg);
}
.post .similar-articles .slick-prev::before {
  content: "";
}
.post .similar-articles .slick-dots {
  right: 0;
  width: 100%;
}
.post .similar-articles .slider_slick_dots {
  position: relative;
}
.post .similar-articles .slider_slick_dots li {
  width: 9px;
  height: 9px;
}
.post .similar-articles .slider_slick_dots li button {
  width: 9px;
  height: 9px;
}
.post .similar-articles .slider_slick_dots li button::before {
  color: #ef4115;
  top: -5px;
  opacity: 1;
  font-size: 20px;
  width: 9px;
  height: 9px;
}
.post .similar-articles .slider_slick_dots .slick-active button::before {
  opacity: 1;
  font-size: 0;
  top: 1px;
  border: 2px solid #ef4115;
  border-radius: 50%;
  width: 8px;
  height: 8px;
}
.post .top-articles--desk {
  display: none;
}
@media (min-width: 992px) {
  .post .top-articles--desk {
    display: block;
  }
  .post .top-articles--desk .title {
    margin-top: 0;
  }
}
.post .top-articles--mob {
  display: block;
}
@media (min-width: 992px) {
  .post .top-articles--mob {
    display: none;
  }
}
.post .top-articles__list {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.post .top-articles__list_item {
  display: inline-block;
  font-weight: 100;
  font-size: 18px;
  line-height: 23px;
  color: #323343;
  background-color: transparent;
  padding: 0;
  border: none;
  border-bottom: 1px dashed #323343;
  cursor: pointer;
  margin-bottom: 10px;
  text-align: left;
  -webkit-transition: 150ms;
  transition: 150ms;
}

.author__wrap {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  position: relative;
}
@media (min-width: 768px) {
  .author__wrap {
    grid-template-columns: 1fr 3fr;
  }
}
.author__left {
  -webkit-box-shadow: 0 2px 5px rgba(108, 108, 140, 0.75);
          box-shadow: 0 2px 5px rgba(108, 108, 140, 0.75);
}
@media (min-width: 768px) {
  .author__left {
    position: sticky;
    top: 90px;
  }
}
.author__right {
  -webkit-box-shadow: 0 2px 5px rgba(108, 108, 140, 0.75);
          box-shadow: 0 2px 5px rgba(108, 108, 140, 0.75);
  padding: 15px;
}
.author__card {
  padding: 20px;
}
.author__card_photo {
  text-align: center;
}
.author__card_photo img {
  width: 150px;
  height: 150px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
}
.author__card_name {
  text-align: center;
  font-size: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
}
.author__card_social {
  border-top: 1px solid #ccc;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
  gap: 10px;
}
.author__card_social a {
  -webkit-transition: 150s;
  transition: 150s;
}
.author__card_social a:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-transition: 150ms;
  transition: 150ms;
}
.author__card_social img {
  width: 25px;
}
.author__info_about {
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
}
.author__info_position {
  display: grid;
  grid-template-columns: 100px auto;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  padding-bottom: 10px;
}
.author__info_position div {
  font-size: 20px;
}
.author__info_position p {
  color: #424242;
}
.author__info_company {
  display: grid;
  grid-template-columns: 100px auto;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  padding-bottom: 10px;
}
.author__info_company div {
  font-size: 20px;
}
.author__info_company p {
  color: #424242;
}
.author__achievement {
  margin-top: 40px;
  margin-bottom: 40px;
}
.author__achievement_wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  gap: 20px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-top: 25px;
}
@media (min-width: 1200px) {
  .author__achievement_wrap {
    padding-top: 35px;
  }
}
.author__achievement_item img {
  max-height: 100px;
  -webkit-transition: 150ms;
  transition: 150ms;
}
.author__achievement_item img:hover {
  opacity: 0.8;
  -webkit-transition: 150ms;
  transition: 150ms;
}

.authors {
  margin-bottom: 60px;
}
.authors__cards_row {
  list-style: none;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}
@media (min-width: 576px) {
  .authors__cards_row {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 768px) {
  .authors__cards_row {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (min-width: 992px) {
  .authors__cards_row {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
.authors__cards_row li {
  -webkit-box-shadow: 0 2px 5px rgba(108, 108, 140, 0.75);
          box-shadow: 0 2px 5px rgba(108, 108, 140, 0.75);
  -webkit-transition: 150ms;
  transition: 150ms;
  color: #292929;
}
.authors__cards_row li:hover .author_card__photo img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-transition: 150ms;
  transition: 150ms;
}
.authors__cards_row .author_card {
  text-align: center;
}
.authors__cards_row .author_card__photo {
  padding: 20px;
}
.authors__cards_row .author_card__photo img {
  width: 150px;
  height: 150px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
}
.authors__cards_row .author_card__title {
  text-align: center;
  font-size: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  color: #292929;
  -webkit-transition: 150ms;
  transition: 150ms;
}
.authors__cards_row .author_card__title:hover {
  color: #ff4b1e;
  -webkit-transition: 150ms;
  transition: 150ms;
}
.authors__cards_row .author_card__text {
  text-align: center;
  font-size: 16px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  color: #292929;
}

.blog__help.help {
  padding-bottom: 30px;
  padding-top: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
  -webkit-box-shadow: 0 2px 5px rgba(108, 108, 140, 0.75);
          box-shadow: 0 2px 5px rgba(108, 108, 140, 0.75);
}
.blog__help.help .help_col__text {
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .blog__help.help .help_col__text {
    margin-left: unset;
    margin-right: unset;
  }
}
.blog__help.help .help_col__button {
  margin-top: 20px;
}
@media (min-width: 768px) {
  .blog__help.help .help_col__button {
    margin-top: 0;
  }
}
.blog .search {
  margin-bottom: 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dadada;
}
.blog .search #search {
  position: relative;
}
.blog .search .close_search {
  position: absolute;
  top: 38px;
  right: 13px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  cursor: pointer;
  -webkit-transition: 150ms;
  transition: 150ms;
  opacity: 0;
}
.blog .search .close_search:hover {
  color: #ef4115;
  -webkit-transition: 150ms;
  transition: 150ms;
}
.blog .search .close_search:after {
  content: "+";
  font-size: 30px;
}

.subscribe_result .message {
  font-size: 28px;
  text-align: center;
}
.subscribe_result .subscribe_result__button {
  font-size: 26px;
  display: block;
  margin: 50px auto;
  padding: 16px 55px;
}

.page_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100vh;
}
.page_wrapper__content {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
  padding-top: 76px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 12;
  width: 100%;
  height: 100%;
}
@media (max-width: 991.98px) {
  .modal {
    top: 80px;
    height: calc(100% - 80px);
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}
.modal.active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10;
}

.modal_container {
  display: block;
  position: relative;
  width: 480px;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  background-color: #fff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.55);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.55);
  overflow-y: auto;
  z-index: 11;
}
.modal_container__link {
  cursor: pointer;
  color: #ff4213;
  border-top: none;
  border-right: none;
  border-bottom: 1px dotted #ff4213;
  border-left: none;
  background-color: transparent;
  padding: 0;
  border-radius: 0;
  display: inline-block;
}
.modal_container__link:hover {
  border-color: transparent;
}
.modal_container__guts {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 20px;
}
@media (min-width: 992px) {
  .modal_container__guts {
    padding: 38px 47px 46px;
  }
}
.modal_container__title {
  font-weight: bold;
  font-size: 26px;
  color: #333;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .modal_container__title {
    font-size: 30px;
  }
}
.modal_container__title_margin_bottom {
  margin-bottom: 30px;
}
.modal_container__text {
  font-weight: 300;
  font-size: 18px;
  color: #323343;
  text-align: center;
  margin-top: 30px;
}
.modal_container__text--left {
  text-align: left;
}
.modal_container__close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 14px;
  height: 14px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.modal_container__close::before {
  content: "";
  position: absolute;
  top: 0;
  left: 6px;
  width: 3px;
  height: 14px;
  background-color: #000;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.modal_container__close::after {
  content: "";
  position: absolute;
  top: 0;
  left: 6px;
  width: 3px;
  height: 14px;
  background-color: #000;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.modal_container .modal_container__button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 20px;
  padding: 0 35px;
  height: 45px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}
.modal_container .modal_container__button_icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 24px;
  height: 24px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-right: 10px;
  padding: 4px;
  border-radius: 50%;
  background-color: white;
}
.modal_container .modal_container__button_icon svg {
  width: 100%;
  height: auto;
}
.modal_container__account_google {
  margin-top: 10px;
  margin-bottom: 15px;
}
.modal_container__account_google .button {
  padding-left: 30px;
  padding-right: 30px;
}
.modal_container__link_button {
  font-size: 23px;
  line-height: 22px;
  font-weight: 500;
  padding: 0 10px;
  color: #ff4213;
  border-bottom: 1px dotted #ff4213;
  background-color: transparent;
  border-top: none;
  border-left: none;
  border-right: none;
}
.modal_container__link_button:hover {
  cursor: pointer;
  border: none;
}

.button {
  display: inline-block;
  padding: 8px 19px;
  font-size: 16px;
  line-height: 1;
  color: #fff;
  background-color: #ef4115;
  border: none;
  border-radius: 5px;
  -webkit-box-shadow: 0 3px #be2f0c;
          box-shadow: 0 3px #be2f0c;
  -webkit-transition: background-color 150ms ease-out, -webkit-transform 150ms ease-out, -webkit-box-shadow 150ms ease-out;
  transition: background-color 150ms ease-out, -webkit-transform 150ms ease-out, -webkit-box-shadow 150ms ease-out;
  transition: background-color 150ms ease-out, transform 150ms ease-out, box-shadow 150ms ease-out;
  transition: background-color 150ms ease-out, transform 150ms ease-out, box-shadow 150ms ease-out, -webkit-transform 150ms ease-out, -webkit-box-shadow 150ms ease-out;
}
.button__sir-trevor {
  margin: 0 auto 40px;
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 100;
  font-size: 20px;
  padding: 17px 30px;
}
@media (min-width: 768px) {
  .button__sir-trevor {
    font-size: 24px;
  }
}
.button--big {
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
  padding: 17px 38px;
  font-size: 20px;
}
@media (min-width: 992px) {
  .button--big {
    padding: 23px 71px;
    font-size: 24px;
    margin-bottom: 120px;
  }
}
.button:hover {
  background-color: #ff4b1e;
}
.button:active {
  -webkit-box-shadow: 0 0 #be2f0c;
          box-shadow: 0 0 #be2f0c;
  -webkit-transform: translateY(3px);
          transform: translateY(3px);
}
.button__icon {
  display: block;
  width: 32px;
  margin-right: 10px;
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
}
.button__text {
  text-align: left;
}
.button__text_type {
  text-transform: uppercase;
}
.button__tiny {
  display: block;
  font-size: 12px;
}
.button--with_icon_mod {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 24px;
  padding: 10px 25px;
}
@media (min-width: 768px) {
  .button--with_icon_mod {
    padding: 20px 50px;
  }
}
@media (min-width: 992px) {
  .button--with_icon_mod {
    padding: 10px 25px;
  }
}
.button--disabled {
  pointer-events: none;
  opacity: 0.5;
}
.button--active {
  -webkit-box-shadow: 0 0 #be2f0c;
          box-shadow: 0 0 #be2f0c;
  -webkit-transform: translateY(3px);
          transform: translateY(3px);
}
.button--sign_up {
  border: 1px solid #afafaf;
  font-size: 14px;
  padding: 7px 6px 6px 7px;
  border-radius: 4px;
  line-height: 11px;
  color: #4c4c4c;
  text-transform: uppercase;
  background-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-transition: 150ms;
  transition: 150ms;
  margin-right: 0;
  margin-left: 20px;
}
@media (min-width: 992px) {
  .button--sign_up {
    margin-right: 20px;
    margin-left: 0;
  }
}
.button--sign_up:hover {
  border-color: #ff4b1e;
  color: #ff4b1e;
  -webkit-transition: 150ms;
  transition: 150ms;
  background-color: transparent;
}

.link_dotted {
  margin: 0;
  color: #ef4115;
  border-bottom: 1px dotted #ef4115;
}
.link_dotted:hover {
  border-bottom-color: transparent;
}

.burger {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 76px;
}
@media (min-width: 992px) {
  .burger {
    display: none;
  }
}
.burger--active {
  position: relative;
}
.burger--active .burger__burger span {
  width: 0;
}
.burger--active .burger__burger span:nth-child(1) {
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}
.burger--active .burger__burger span:nth-child(2) {
  -webkit-transition-delay: 0.125s;
          transition-delay: 0.125s;
}
.burger--active .burger__burger span:nth-child(3) {
  -webkit-transition-delay: 0.25s;
          transition-delay: 0.25s;
}
.burger--active .burger__cross {
  opacity: 1;
  display: block;
}
.burger--active .burger__cross span:nth-child(1) {
  height: 29px;
  -webkit-transition-delay: 0.425s;
          transition-delay: 0.425s;
}
.burger--active .burger__cross span:nth-child(2) {
  width: 29px;
  -webkit-transition-delay: 0.275s;
          transition-delay: 0.275s;
}
.burger__icon {
  position: relative;
  width: 30px;
  height: 30px;
  cursor: pointer;
  float: right;
  background-color: transparent;
  border: none;
  padding: 0;
}
.burger__icon .burger__burger span {
  display: block;
  background: #292929;
  border-radius: 2px;
  -webkit-transition: width 150ms, height 150ms, opacity 150ms, -webkit-transition-delay 150ms;
  transition: width 150ms, height 150ms, opacity 150ms, -webkit-transition-delay 150ms;
  transition: width 150ms, height 150ms, transition-delay 150ms, opacity 150ms;
  transition: width 150ms, height 150ms, transition-delay 150ms, opacity 150ms, -webkit-transition-delay 150ms;
}
.burger__burger {
  position: absolute;
  display: block;
  top: 0;
  height: 100%;
  width: 100%;
}
.burger__burger span {
  position: relative;
  width: 30px;
  height: 3px;
  top: 1px;
  left: 0;
  margin: 5px 0;
}
.burger__burger span:nth-child(1) {
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}
.burger__burger span:nth-child(2) {
  -webkit-transition-delay: 0.125s;
          transition-delay: 0.125s;
}
.burger__burger span:nth-child(3) {
  -webkit-transition-delay: 0.25s;
          transition-delay: 0.25s;
}
.burger__cross {
  position: absolute;
  display: block;
  top: 0;
  height: 100%;
  width: 100%;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  opacity: 0;
}
.burger__cross span {
  background: #292929;
}
.burger__cross span:nth-child(1) {
  height: 0;
  width: 3px;
  position: absolute;
  top: 0;
  left: 13px;
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}
.burger__cross span:nth-child(2) {
  width: 0;
  height: 3px;
  position: absolute;
  left: 0;
  top: 13px;
  -webkit-transition-delay: 0.25s;
          transition-delay: 0.25s;
}

.informer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  list-style-type: none;
}
.informer__item {
  display: block;
}
.informer__item:not(:last-of-type) {
  margin-right: 10px;
}
.informer__link {
  display: block;
}
.informer__link img {
  display: block;
}

.social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style-type: none;
  margin-top: 20px;
}
@media (min-width: 768px) {
  .social {
    margin-top: 0;
    margin-left: 60px;
  }
}
.social__link {
  display: block;
  margin: 0 5px;
  font-size: 0;
}
.social__link svg {
  width: 24px;
  height: auto;
  fill: #323232;
  -webkit-transition: fill 0.15s ease-out;
  transition: fill 0.15s ease-out;
}
.social__link:hover svg {
  fill: #ef4115;
}
.social__link_facebook {
  background-color: #323232;
  background-image: url(d333f98f1c35fdf38ce09d897b953459.svg);
  background-position: 11px 6px;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  border-radius: 5px;
  -webkit-transition: background-color 0.15s ease-out;
  transition: background-color 0.15s ease-out;
}
.social__link_facebook:hover {
  background-color: #ef4115;
}

.form_checkbox {
  margin-top: 15px;
}
.form_checkbox__box {
  display: inline-block;
  position: relative;
  margin-top: 4px;
  margin-right: 8px;
  min-width: 12px;
  min-height: 12px;
  width: 12px;
  height: 12px;
  border: 1px solid #ccc;
}
.form_checkbox__box::before {
  content: "";
  display: none;
  position: absolute;
  top: 1px;
  left: 1px;
  background-image: url(cc04f5df6cc0bcd9703f889de960b7ef.png);
  background-repeat: no-repeat;
  width: 8px;
  height: 8px;
}
input:checked + label .form_checkbox__box::before {
  display: block;
}
.form_checkbox__text {
  display: inline;
}
.form_checkbox__text a {
  color: #ff4213;
  border-bottom: 1px dotted #ff4b1e;
}
.form_checkbox input {
  display: none;
}
.form_checkbox input:disabled + label {
  cursor: default;
}
.form_checkbox input:disabled + label .checkbox__box, .form_checkbox input:disabled + label .form_checkbox__box {
  pointer-events: none;
  background-color: #dedede;
  opacity: 0.6;
}
.form_checkbox label {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-weight: 100;
  cursor: pointer;
}
.form_input {
  position: relative;
  display: block;
  width: 100%;
  font-weight: 100;
  font-size: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.wrapper_error .form_input__input {
  border-color: #ff8282;
  background-color: #ffe5e5;
  -webkit-box-shadow: 0 3px 6px #ecd4d4 inset;
          box-shadow: 0 3px 6px #ecd4d4 inset;
  padding-right: 40px;
}
.wrapper_error .form_input__icon {
  display: block;
}
.form_input__label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #323343;
  margin-bottom: 3px;
  font-size: 16px;
}
@media (min-width: 768px) {
  .form_input__label {
    font-size: 18px;
  }
}
.form_input__label_star {
  color: #ef4115;
  margin-left: 5px;
}
.form_input__error {
  display: none;
  font-size: 14px;
  color: #ef4115;
  margin-left: 20px;
  position: absolute;
  right: 0;
  top: 10px;
}
@media (min-width: 768px) {
  .form_input__error {
    font-size: 15px;
    top: 13px;
  }
}
.form_input__error::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  width: calc(100% + 30px);
  background: -webkit-gradient(linear, left top, right top, from(rgba(14, 0, 255, 0)), color-stop(12%, white));
  background: linear-gradient(90deg, rgba(14, 0, 255, 0) 0%, white 12%);
}
.form_input__error span {
  position: relative;
  z-index: 2;
}
.form_input__error:not(:first-of-type) {
  display: none;
}
.form_input__error_email {
  display: none;
}
.form_input.error_email .form_input__error_empty {
  display: none;
}
.form_input.error_email .form_input__error_email {
  display: inline;
}
.wrapper_error .form_input__error {
  display: block;
}
.form_input__input {
  position: relative;
  width: 100%;
  height: 45px;
  padding: 0 12px;
  -webkit-box-shadow: inset 0 3px 6px #ebebeb;
          box-shadow: inset 0 3px 6px #ebebeb;
  border: 1px solid #cdcdcd;
  line-height: 25px;
  color: #585858;
}
.form_input__input::-webkit-input-placeholder {
  opacity: 0.7;
}
.form_input__input::-moz-placeholder {
  opacity: 0.7;
}
.form_input__input:-ms-input-placeholder {
  opacity: 0.7;
}
.form_input__input::-ms-input-placeholder {
  opacity: 0.7;
}
.form_input__input::placeholder {
  opacity: 0.7;
}
.form_input__icon {
  display: none;
  position: absolute;
  right: 10px;
  bottom: 24px;
  width: 20px;
  height: 20px;
  z-index: 1;
}
.form_input.wrapper_error input:-internal-autofill-selected {
  background-color: #ff8282 !important;
}
.form_select {
  position: relative;
  display: block;
  width: 100%;
  font-weight: 100;
  font-size: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.wrapper_error .form_select__select {
  border-color: #ff8282;
  background-color: #ffe5e5;
  -webkit-box-shadow: 0 3px 6px #ecd4d4 inset;
          box-shadow: 0 3px 6px #ecd4d4 inset;
  padding-right: 40px;
}
.wrapper_error .form_select__icon {
  display: block;
}
.form_select__label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #323343;
  margin-bottom: 3px;
  font-size: 16px;
}
@media (min-width: 768px) {
  .form_select__label {
    font-size: 18px;
  }
}
.form_select__label_star {
  color: #ef4115;
  margin-left: 5px;
}
.form_select__error {
  display: none;
  font-size: 14px;
  color: #ef4115;
  margin-left: 20px;
  position: absolute;
  right: 0;
  top: 10px;
}
@media (min-width: 768px) {
  .form_select__error {
    font-size: 15px;
    top: 13px;
  }
}
.form_select__error::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  width: calc(100% + 30px);
  background: -webkit-gradient(linear, left top, right top, from(rgba(14, 0, 255, 0)), color-stop(12%, white));
  background: linear-gradient(90deg, rgba(14, 0, 255, 0) 0%, white 12%);
}
.form_select__error span {
  position: relative;
  z-index: 2;
}
.form_select__error:not(:first-of-type) {
  display: none;
}
.form_select__error_email {
  display: none;
}
.form_select.error_email .form_input__error_empty {
  display: none;
}
.form_select.error_email .form_input__error_email {
  display: inline;
}
.wrapper_error .form_select__error {
  display: block;
}
.form_select__select {
  position: relative;
  width: 100%;
  height: 45px;
  padding: 0 22px 0 12px;
  background-color: transparent;
  -webkit-box-shadow: inset 0 3px 6px #ebebeb;
          box-shadow: inset 0 3px 6px #ebebeb;
  border: 1px solid #cdcdcd;
  line-height: 25px;
  color: #585858;
  cursor: pointer;
  z-index: 1;
}
.form_select__select:focus {
  background-color: transparent;
}
.form_select__select:invalid {
  opacity: 0.7;
}
.form_select__select_wrapper {
  position: relative;
  background-color: white;
}
.form_select__select_wrapper:after {
  content: "";
  display: block;
  position: absolute;
  right: 10px;
  top: calc(50% - 3px);
  width: 0;
  height: 0;
  border: 6px solid #b6b6b6;
  border-bottom: 0 solid transparent;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  z-index: 0;
}
.form_select__icon {
  display: none;
  position: absolute;
  right: 10px;
  bottom: 24px;
  width: 20px;
  height: 20px;
  z-index: 1;
}
.form_select.wrapper_error input:-internal-autofill-selected {
  background-color: #ff8282 !important;
}
.form_textarea {
  position: relative;
  display: block;
  width: 100%;
  font-weight: 100;
  font-size: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.wrapper_error .form_textarea__textarea {
  border-color: #ff8282;
  background-color: #ffe5e5;
  -webkit-box-shadow: 0 3px 6px #ecd4d4 inset;
          box-shadow: 0 3px 6px #ecd4d4 inset;
}
.wrapper_error .form_textarea__icon {
  display: block;
}
.form_textarea__label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #323343;
  margin-bottom: 3px;
  font-size: 16px;
}
@media (min-width: 768px) {
  .form_textarea__label {
    font-size: 18px;
  }
}
.form_textarea__label_star {
  color: #ef4115;
  margin-left: 5px;
}
.form_textarea__error {
  display: none;
  font-size: 14px;
  color: #ef4115;
}
.form_textarea__error:not(:first-of-type) {
  display: none;
}
@media (min-width: 768px) {
  .form_textarea__error {
    font-size: 14px;
  }
}
.wrapper_error .form_textarea__error {
  display: block;
}
.form_textarea__textarea {
  position: relative;
  width: 100%;
  height: 100px;
  min-height: 100px;
  padding: 12px;
  -webkit-box-shadow: inset 0 3px 6px #ebebeb;
          box-shadow: inset 0 3px 6px #ebebeb;
  border: 1px solid #cdcdcd;
  line-height: 25px;
  color: #585858;
  resize: none;
}
.form_textarea__textarea::-webkit-input-placeholder {
  opacity: 0.7;
}
.form_textarea__textarea::-moz-placeholder {
  opacity: 0.7;
}
.form_textarea__textarea:-ms-input-placeholder {
  opacity: 0.7;
}
.form_textarea__textarea::-ms-input-placeholder {
  opacity: 0.7;
}
.form_textarea__textarea::placeholder {
  opacity: 0.7;
}
.form_textarea__icon {
  display: none;
  position: absolute;
  right: 10px;
  top: 44px;
  width: 20px;
  height: 20px;
  z-index: 1;
}

.page_title {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (min-width: 768px) {
  .page_title {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
}
.page_title__text {
  margin: 20px 0;
  font-size: 38px;
  line-height: 1;
  font-weight: 100;
  color: #333;
}
@media (min-width: 768px) {
  .page_title__text {
    margin: 60px 0;
    font-size: 60px;
  }
}
.page_title img {
  width: 100%;
  max-width: 80px;
  margin-top: 15px;
}
@media (min-width: 768px) {
  .page_title img {
    max-width: 110px;
    margin: 35px 0 35px 20px;
  }
}
.page_title img + .page_title__text {
  margin: 0 0 20px;
}
@media (min-width: 768px) {
  .page_title img + .page_title__text {
    margin: 60px 0;
  }
}
.page_title--centre_mod {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.help {
  display: block;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 100px;
}
.help__row {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}
@media (min-width: 768px) {
  .help__row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}
.help_col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
}
.help_col__button {
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .help_col__button {
    margin-right: inherit;
  }
}
.help_col__button--about {
  padding: 8px 19px;
}
@media (min-width: 768px) {
  .help_col__button--about {
    padding: 25px 65px;
  }
}
.help_col__button--help {
  padding: 8px 19px;
}
@media (min-width: 768px) {
  .help_col__button--help {
    padding: 25px 20px;
    margin-left: 0;
  }
}
@media (min-width: 1200px) {
  .help_col__button--help {
    padding: 25px 50px;
  }
}
.help_col__text {
  font-size: 18px;
  font-weight: 100;
  color: #557;
  text-align: center;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .help_col__text {
    text-align: left;
    max-width: 400px;
    margin-right: auto;
    margin-bottom: 0;
  }
}
.help_col__text:last-child {
  margin-bottom: 0;
}

.section_title {
  font-family: "decimaNova", sans-serif;
  font-weight: bold;
  font-size: 30px;
  text-align: center;
  color: #333;
  margin-bottom: 35px;
  margin-top: 0;
}

.list_box {
  display: none;
  position: absolute;
  top: -10px;
  right: -10px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  list-style-type: none;
  background-color: #fff;
  padding: 10px;
  -webkit-box-shadow: 0 2px 7px #e0e0e0;
          box-shadow: 0 2px 7px #e0e0e0;
  z-index: 1;
}
.list_box.show {
  display: block;
}
.list_box__item {
  text-align: right;
  color: #323232;
  font-weight: 100;
}
.list_box__item_active {
  color: #ef4115;
  font-weight: bold;
}

.user_profile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 40px;
}
.user_profile img {
  width: 65px;
  height: 65px;
}
.user_profile__text {
  padding-left: 16px;
  font-size: 18px;
}
.user_profile__name {
  font-weight: bold;
  color: #333;
  margin-bottom: 1px;
}
.user_profile__company {
  font-weight: 100;
  color: #557;
}
.user_over {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  font-size: 18px;
  font-weight: 100;
  line-height: 26px;
  color: #557;
}
.user_over:not(:last-of-type) {
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .user_over {
    font-size: 22px;
  }
}
.user_over__title {
  margin-right: 10px;
}

.link_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  z-index: 1;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  font-size: 18px;
  font-weight: 100;
  line-height: 26px;
  color: #557;
}
@media (min-width: 768px) {
  .link_box {
    font-size: 22px;
  }
}
.link_box:hover .link_box__block {
  display: block;
}
.link_box__company {
  display: inline-block;
  position: relative;
  color: #ff4213;
  border-bottom: 1px dotted #ff4b1e;
  cursor: pointer;
  z-index: 3;
}
.link_box__block {
  display: none;
  position: absolute;
  top: -36px;
  left: -10px;
  background-color: #fff;
  padding: 80px 10px 20px;
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.18);
          box-shadow: 0 1px 6px rgba(0, 0, 0, 0.18);
  min-width: 310px;
  z-index: 2;
}
@media (min-width: 768px) {
  .link_box__block {
    left: -40px;
    padding: 80px 40px 20px;
    min-width: 400px;
  }
}

/* Slider */
/* Icons */
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "\2190";
}
[dir=rtl] .slick-prev:before {
  content: "\2192";
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "\2192";
}
[dir=rtl] .slick-next:before {
  content: "\2190";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "\2022";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

.scrollToTopBtn {
  background-image: url(5e88d34b141f99dc83d30ef6667f8488.svg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 5px;
  background-color: #292929;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 18px;
  line-height: 48px;
  width: 48px;
  height: 48px;
  position: fixed;
  bottom: 85px;
  right: 20px;
  z-index: 100;
  opacity: 0;
  -webkit-transform: translateY(100px);
          transform: translateY(100px);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.scrollToTopBtn:hover {
  background-color: #ee4115;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.showBtn {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.drop_zone {
  margin-bottom: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 100;
}
.drop_zone__label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #323343;
  margin-bottom: 3px;
  font-size: 16px;
}
@media (min-width: 768px) {
  .drop_zone__label {
    font-size: 18px;
  }
}
.drop_zone__label_star {
  color: #ef4115;
  margin-left: 5px;
}
.drop_zone__zone {
  min-height: 90px;
  border: 2px dashed #cdcdcd;
  border-radius: 5px;
  font-size: 18px;
}
.drop_zone .dz-message {
  text-align: center;
  margin: 1em 0;
}
.drop_zone .dz-preview {
  margin-top: 36px;
}
.drop_zone__remove {
  position: absolute;
  top: -25px;
  cursor: pointer !important;
  z-index: 30;
}

.pagination {
  display: block;
}
.pagination__list {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  color: #292929;
  font-size: 12px;
  font-weight: 700;
}
@media (min-width: 768px) {
  .pagination__list {
    font-size: 16px;
  }
}
.pagination__item {
  padding: 10px;
  width: 38px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (min-width: 768px) {
  .pagination__item {
    width: 44px;
  }
}
.pagination__item_link {
  color: #292929;
}
.pagination__item_link:hover {
  color: #ff4213;
}
.pagination__item.active .pagination__item_link {
  color: #ff4213;
  cursor: initial;
}
.pagination__item.disabled .pagination__item_link {
  color: #ccc;
  cursor: initial;
}
.pagination__item--prev, .pagination__item--next {
  padding: 7px 21px;
}
.pagination__item--prev .pagination__item_link, .pagination__item--next .pagination__item_link {
  font-size: 0;
  position: relative;
}
.pagination__item--prev .pagination__item_link:before, .pagination__item--next .pagination__item_link:before {
  content: "";
  display: block;
  position: absolute;
  bottom: 2px;
  width: 8px;
  height: 8px;
  border-right: 2px solid;
  border-bottom: 2px solid;
}
.pagination__item--next .pagination__item_link:before {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  left: -6px;
}
.pagination__item--prev .pagination__item_link:before {
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
  left: -2px;
}
.pagination__skip {
  letter-spacing: 3.3px;
}

#loading {
  width: 100%;
  height: 300px;
  background-color: #ededed;
  text-align: center;
  border-radius: 5px;
}
#loading .svg_loader {
  width: 200px;
  margin-top: 50px;
}

.link {
  display: inline;
  color: #ff4213;
  outline: none;
  text-decoration: underline;
  font-family: "decimaNova", sans-serif;
  cursor: pointer;
  font-weight: 400;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-transition: -webkit-box-shadow 0.5s cubic-bezier(0.2, 0.57, 0.36, 0.8);
  transition: -webkit-box-shadow 0.5s cubic-bezier(0.2, 0.57, 0.36, 0.8);
  transition: box-shadow 0.5s cubic-bezier(0.2, 0.57, 0.36, 0.8);
  transition: box-shadow 0.5s cubic-bezier(0.2, 0.57, 0.36, 0.8), -webkit-box-shadow 0.5s cubic-bezier(0.2, 0.57, 0.36, 0.8);
}
.link:hover {
  display: inline;
  text-decoration: none;
  -webkit-box-shadow: 0 0 0 transparent, 0 1px 0 #ff4213;
          box-shadow: 0 0 0 transparent, 0 1px 0 #ff4213;
}
.link:active {
  color: #ff562d;
  text-decoration: none;
}
.link--no_hover:hover {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.link--white:hover {
  -webkit-box-shadow: 0 0 0 transparent, 0 1px 0 #fff;
          box-shadow: 0 0 0 transparent, 0 1px 0 #fff;
}
.link--white:active {
  color: #fff;
  text-decoration: none;
}
.link--red {
  color: #ef4115;
  border-bottom: 1px solid;
}
.link--red:hover {
  border-bottom: none;
}

.video-container {
  position: relative;
  padding-bottom: 53.91%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}
.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.typography {
  font-family: "decimaNova", sans-serif;
  font-weight: 100;
  font-size: 16px;
  line-height: 1.4;
  color: #424242;
}
.typography h1 {
  margin: 2.8rem 0 1.6rem 0;
  color: #000;
  font-family: "decimaNova", sans-serif;
  font-size: 32px;
}
.typography h2 {
  margin: 2.8rem 0 1.6rem 0;
  color: #000;
  font-family: "decimaNova", sans-serif;
  font-size: 26px;
}
.typography h3 {
  margin: 2.8rem 0 1.6rem 0;
  color: #000;
  font-family: "decimaNova", sans-serif;
  font-size: 22px;
}
.typography h4 {
  margin: 2.8rem 0 1.6rem 0;
  color: #000;
  font-family: "decimaNova", sans-serif;
  font-size: 18px;
}
.typography h5 {
  margin: 2.8rem 0 1.6rem 0;
  color: #000;
  font-family: "decimaNova", sans-serif;
  font-size: 16px;
}
.typography h6 {
  margin: 2.8rem 0 1.6rem 0;
  color: #000;
  font-family: "decimaNova", sans-serif;
  font-size: 15px;
}
.typography h1 {
  font-weight: 400;
}
.typography h2 {
  font-weight: 400;
}
.typography h3 {
  font-weight: 400;
}
.typography h4 {
  font-weight: 400;
}
.typography h5 {
  font-weight: 400;
}
.typography h6 {
  font-weight: 400;
}
.typography a {
  display: inline;
  color: #ff4213;
  outline: none;
  text-decoration: underline;
  font-family: "decimaNova", sans-serif;
  cursor: pointer;
  font-weight: 100;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.typography a:hover {
  display: inline;
  text-decoration: none;
}
.typography a:active {
  color: #ff562d;
  text-decoration: none;
}
.typography a--no_hover:hover {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.typography p,
.typography ul,
.typography hr,
.typography dl,
.typography pre,
.typography address,
.typography blockquote,
.typography table {
  margin: 0 0 1.4em 0;
}
.typography b,
.typography strong {
  color: inherit;
  font-weight: bold;
}
.typography i,
.typography cite,
.typography em,
.typography var,
.typography address,
.typography dfn,
.typography caption {
  font-style: italic;
}
.typography small {
  font-size: 70%;
  font-weight: 400;
}
.typography ul {
  list-style: none !important;
  list-style-position: inside;
  padding: 0;
}
.typography ul li {
  overflow: hidden;
  position: relative;
  margin: 5px 0;
  padding-left: 30px;
}
.typography ul li:before {
  content: "";
  display: block;
  margin: 0;
  position: absolute;
  top: 8px;
  left: 15px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #ef4115;
}
.typography ul li ul li:before {
  top: 10px;
  left: 12px;
  width: 8px;
  height: 1px;
}
.typography ol {
  list-style: none inside;
  counter-reset: item;
  padding: 0;
}
.typography ol li {
  overflow: hidden;
  margin: 5px 0;
  padding-left: 35px;
}
.typography ol li:before {
  content: counters(item, ".") ".";
  counter-increment: item;
  position: relative;
  display: inline-block;
  min-width: 25px;
  margin-left: 6px;
  margin-right: 0;
  padding: 2px 3px 1px;
  font-size: 100%;
  font-weight: 700;
  text-align: center;
  color: #ef4115;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.typography ol li ul {
  margin-top: 20px;
  margin-bottom: 0;
}
.typography ol li ul li {
  position: relative;
  margin: 5px 0;
}
.typography ol li ul li:before {
  content: "";
  counter-increment: none;
  display: block;
  margin: 0;
  position: absolute;
  top: 8px;
  left: 15px;
  width: 5px;
  min-width: 5px;
  height: 5px;
  min-height: 5px;
  border-radius: 50%;
  background: #ef4115;
}
.typography img {
  max-width: 100%;
  max-height: 100%;
  -webkit-box-shadow: 0 2px 3px rgba(108, 108, 140, 0.75);
          box-shadow: 0 2px 3px rgba(108, 108, 140, 0.75);
}
.typography ins {
  text-decoration: none;
  border-bottom: 2px solid #6c757d;
}
.typography table {
  display: block;
  max-width: 100% !important;
  overflow-x: auto;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
  border: none;
}
.typography table.table-null, .typography table.table-null th, .typography table.table-null td {
  border-color: transparent;
}
.typography table.table-null th {
  background: transparent;
}
.typography table.table-zebra, .typography table.table-zebra th, .typography table.table-zebra td {
  border-color: transparent;
}
.typography table.table-zebra tr:nth-child(even) {
  background-color: #eee;
}
.typography table ol li,
.typography table ul li {
  display: list-item;
}
.typography tbody,
.typography tfoot,
.typography thead,
.typography tr,
.typography th,
.typography td {
  border: inherit;
}
.typography th,
.typography td {
  padding: 10px;
  text-align: left;
  vertical-align: top;
  border: 1px solid #ccc;
}
.typography th {
  text-align: center;
  background-color: #ddd;
}
.typography dd + dt {
  margin: 1.4em 0 0;
}
.typography dd {
  margin-left: 32px;
}
.typography dt,
.typography th {
  font-weight: bold;
}
.typography pre {
  -moz-tab-size: 4;
    -o-tab-size: 4;
       tab-size: 4;
  padding: 15px;
  background-color: #f5f5f5;
}
.typography code,
.typography kbd,
.typography samp {
  padding: 0 3px;
}
.typography code {
  background-color: #f5f5f5;
  color: #323343;
  padding: 5px 11px 6px;
  font-family: "Courier New", sans-serif;
}
.typography kbd {
  color: #000;
  background-color: #e1f0fa;
}
.typography samp {
  background-color: #f7f8e2;
}
.typography mark {
  padding: 0 0.2em;
  color: #424242;
  background-color: #feec91;
  font-family: "decimaNova", sans-serif;
}
.typography q:before {
  content: open-quote;
}
.typography q:after {
  content: close-quote;
}
.typography blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C" "\201D" "\2018" "\2019";
}
.typography blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
.typography blockquote p {
  display: inline;
}
.typography hr {
  clear: both;
  margin: 50px 0;
  border: 0;
  border-top: 1px solid #000;
}

.page_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100vh;
}

.page_wrapper__content {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
  padding-top: 76px;
}

.page_wrapper__footer {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  width: 100%;
  height: 76px;
  -webkit-box-shadow: 0 2px 3px rgba(108, 108, 140, 0.75);
          box-shadow: 0 2px 3px rgba(108, 108, 140, 0.75);
  z-index: 11;
}
.header_buttons__menu {
  position: relative;
  display: inline-block;
  -webkit-transition: 300ms ease-out;
  transition: 300ms ease-out;
  cursor: pointer;
  margin-left: 20px;
}
.header_buttons__menu .burger--desk {
  display: none;
}
@media (min-width: 992px) {
  .header_buttons__menu .burger--desk {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.header_buttons__menu .burger--desk .burger__icon span {
  background: #ff4213;
}
.header_buttons__menu_wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: unset;
  -webkit-box-shadow: none;
          box-shadow: none;
}
@media (min-width: 992px) {
  .header_buttons__menu_wrap {
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: 190px;
    -webkit-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 0;
    z-index: 1;
    -webkit-transition: 300ms ease-out;
    transition: 300ms ease-out;
    right: 0;
    top: 55px;
  }
}
.header_buttons__menu_list {
  list-style: none;
}
.header_buttons__menu_item {
  padding: 10px 0;
}
.header_buttons__menu_link, .header .header_site_language__link {
  color: #292929;
  font-size: 18px;
  -webkit-transition: 300ms ease-out;
  transition: 300ms ease-out;
  padding: 15px;
}
.header_buttons__menu_link:hover, .header .header_site_language__link:hover {
  color: #ff4213;
}
.header_buttons__menu_link_active {
  color: #ff4213;
}
.header_buttons__menu:hover .header_buttons__menu_wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-transition: 300ms ease-out;
  transition: 300ms ease-out;
}
.header--active {
  height: 100%;
  overflow-y: auto;
}
@media (min-width: 992px) {
  .header--active {
    height: 76px;
  }
}
.header--active .header__row {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  height: 100%;
}
@media (min-width: 992px) {
  .header--active .header__row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    height: 100%;
  }
}
.header--active .header_nav {
  display: block;
  height: auto;
  width: 100%;
  margin-bottom: 10px;
}
@media (min-width: 992px) {
  .header--active .header_nav {
    padding: 0;
    width: auto;
    height: auto;
    -webkit-box-flex: inherit;
        -ms-flex-positive: inherit;
            flex-grow: inherit;
    margin-bottom: 0;
  }
}
.header--active .header_nav__list {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
@media (min-width: 992px) {
  .header--active .header_nav__list {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}
.header--active .header_nav__link {
  font-size: 24px;
}
@media (min-width: 992px) {
  .header--active .header_nav__link {
    font-size: 20px;
  }
}
.header--active .header_buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  margin-right: auto;
  padding-bottom: 20px;
}
.header--active .header_buttons__lang {
  margin-left: 20px;
  bottom: 0px;
  margin-top: auto;
}
.header--active .header_buttons__menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-top: 5px;
}
.header--active .header_buttons__menu_wrap {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
}
.header--active .header_buttons__menu_list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.header--active .header_buttons__menu_link, .header--active .header .header_site_language__link, .header .header--active .header_site_language__link {
  padding: 0;
  font-size: 24px;
}
.header--active .header_buttons__menu_item {
  padding: 0 0 15px;
}
.header--active .header_buttons__name {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
  font-size: 17px;
  padding: 5px 0;
}
@media (min-width: 992px) {
  .header--active .header_buttons {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    margin-right: inherit;
    padding-bottom: 0;
  }
  .header--active .header_buttons__lang {
    margin-left: 0;
  }
}
.header__container {
  width: 100%;
  height: 100%;
}
.header__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
}
.header_mobile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}
@media (min-width: 992px) {
  .header_mobile {
    width: auto;
  }
}
.header_mobile__logo {
  display: block;
}
.header_mobile__logo img {
  display: inherit;
  width: 130px;
  height: 32px;
}
.header div.header_nav__link {
  position: relative;
  width: 100%;
}
.header div.header_nav__link:hover {
  color: #ff4213;
}
.header div.header_nav__link:hover:after {
  color: #ff4213;
}
.header div.header_nav__link:after {
  content: "+";
  display: block;
  position: absolute;
  right: 0;
  top: -12px;
  font-size: 40px;
}
@media (min-width: 992px) {
  .header div.header_nav__link:after {
    display: none;
  }
}
.header_nav {
  display: none;
  height: 100%;
}
@media (min-width: 992px) {
  .header_nav {
    display: block;
  }
}
.header_nav__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  list-style-type: none;
  row-gap: 15px;
}
@media (min-width: 992px) {
  .header_nav__list {
    height: 100%;
    row-gap: 0;
  }
}
.header_nav__item {
  width: 100%;
}
@media (min-width: 992px) {
  .header_nav__item {
    width: auto;
  }
  .header_nav__item:not(:last-of-type) {
    margin-right: 16px;
  }
}
.header_nav__link {
  color: #292929;
  font-size: 18px;
  -webkit-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
  cursor: pointer;
  position: relative;
}
.header_nav__link:hover {
  color: #ff4213;
}
.header_nav__link:hover .header_nav__link_list:first-child {
  display: grid;
  max-height: 200px;
}
.header_nav__link_active {
  color: #ff4213;
}
.header_nav__link_list {
  width: 250px;
  padding: 0px 20px;
  white-space: nowrap;
  max-height: 0;
  -webkit-transition: max-height 0.5s ease-in-out;
  transition: max-height 0.5s ease-in-out;
  overflow: hidden;
  display: grid;
}
.header_nav__link_list:target {
  max-height: 200px;
  -webkit-transition: max-height 0.5s ease-in-out;
  transition: max-height 0.5s ease-in-out;
}
@media (min-width: 992px) {
  .header_nav__link_list {
    width: unset;
    display: none;
    position: absolute;
    background-color: white;
    padding: 15px 20px;
    row-gap: 15px;
    white-space: nowrap;
    max-height: unset;
    -webkit-box-shadow: 0 2px 3px rgba(108, 108, 140, 0.75);
            box-shadow: 0 2px 3px rgba(108, 108, 140, 0.75);
  }
}
.header_nav__link_item {
  color: #292929;
  font-size: 18px;
  -webkit-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
  line-height: 2;
}
@media (min-width: 992px) {
  .header_nav__link_item {
    line-height: 1.1;
  }
}
.header_nav__link_item:hover {
  color: #ff4213;
}
.header_nav__link_item_active {
  color: #ff4213;
}
.header_buttons {
  display: none;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
}
@media (min-width: 992px) {
  .header_buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.header_buttons__lang {
  border: 1px solid #afafaf;
  font-size: 14px;
  padding: 7px 6px 6px 7px;
  border-radius: 4px;
  line-height: 11px;
  color: #4c4c4c;
  text-transform: uppercase;
  background-color: transparent;
  cursor: pointer;
  margin-right: 10px;
  -webkit-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
.header_buttons__lang:hover {
  border-color: #ff4b1e;
  color: #ff4b1e;
}
.header_buttons__name {
  font-size: 14px;
}
@media (min-width: 992px) {
  .header_buttons__name {
    padding: 10px 15px;
    border-bottom: 1px solid #ccc;
    font-size: 18px;
    cursor: default;
  }
}
.header_buttons__close {
  background-color: transparent;
  border: none;
  font-weight: bold;
  color: #ef4115;
  margin-left: 5px;
}
.header .header_site_language {
  position: relative;
  margin-left: 20px;
  bottom: 0;
  margin-top: 0;
}
@media (min-width: 992px) {
  .header .header_site_language {
    margin-left: unset;
    bottom: unset;
    margin-top: unset;
  }
}
.header .header_site_language:hover .header_site_language__dropdown {
  display: grid;
}
.header .header_site_language__current_lang {
  border: 1px solid #afafaf;
  font-size: 14px;
  padding: 7px 6px 6px 7px;
  border-radius: 4px;
  line-height: 11px;
  color: #4c4c4c;
  text-transform: uppercase;
  background-color: transparent;
  cursor: pointer;
  margin-right: 20px;
  -webkit-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
.header .header_site_language__current_lang:hover {
  border-color: #ff4b1e;
  color: #ff4b1e;
}
.header .header_site_language__dropdown {
  display: none;
  position: absolute;
  bottom: 100%;
  left: 0;
  grid-auto-rows: 32px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 10px 15px;
  background-color: white;
  -webkit-box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  list-style: none;
  z-index: 1;
}
@media (min-width: 992px) {
  .header .header_site_language__dropdown {
    top: 100%;
    bottom: auto;
  }
}
.header .header_site_language__link {
  padding-left: 0;
  padding-right: 0;
  white-space: nowrap;
}

.breadcrumb {
  margin-bottom: 20px;
  margin-top: 20px;
}
.breadcrumb__list {
  display: inline;
}
.breadcrumb__item {
  padding: 0;
  list-style: none;
  position: relative;
  display: inline;
}
.breadcrumb__item:not(:first-child) {
  padding-left: 20px;
}
.breadcrumb__item:not(:first-child):before {
  content: "/";
  display: inline-block;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  color: #424242;
  position: absolute;
  left: 0;
}
.breadcrumb__item.active .breadcrumb__link {
  color: #292929;
  text-decoration: none;
  cursor: auto;
  pointer-events: none;
}
.breadcrumb__link {
  font-size: 14px;
  color: #ef4115;
  text-decoration: none;
}
.breadcrumb__link:hover span {
  color: #000;
}
.breadcrumb__name {
  font-size: 14px;
}

.companies {
  margin-top: 45px;
  margin-bottom: 60px;
}
@media (min-width: 768px) {
  .companies {
    margin-bottom: 0;
  }
}
.companies__sir-trevor {
  margin-top: 0;
  margin-bottom: 40px;
}
.companies__title {
  font-family: "decimaNova", sans-serif;
  font-size: 20px;
  font-weight: 100;
  color: #333;
  text-align: center;
  margin-bottom: 30px;
}
.companies_list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: auto;
  list-style-type: none;
}
@media (min-width: 768px) {
  .companies_list {
    height: 70px;
  }
}
.companies_list__link {
  display: block;
  position: relative;
  max-width: 130px;
  height: 70px;
}
.companies_list__link img {
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
  width: 100%;
  height: 100%;
}
@media (min-width: 1200px) {
  .companies_list__link img {
    width: 90px;
  }
}
.companies_list__item {
  -webkit-transition: opacity 1200ms;
  transition: opacity 1200ms;
  opacity: 0;
  margin: 0 5px;
}
.companies__enter {
  opacity: 0.01;
}
.companies__enter_active {
  opacity: 1;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}
.companies__exit {
  opacity: 1;
}
.companies__exit_active {
  opacity: 0.01;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}

.typography .companies_list__link img {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.typography .companies_list__item {
  padding-left: 0;
}
.typography .companies_list__item:before {
  content: none;
}

.page_subtitle {
  margin: 20px 0;
  text-align: center;
  font-size: 38px;
  font-weight: 100;
  color: #333;
}
@media (min-width: 768px) {
  .page_subtitle {
    margin: 60px 0;
  }
}
@media (min-width: 992px) {
  .page_subtitle {
    margin: 100px 0;
  }
}

.advantage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 100px;
}
.advantage:first-of-type {
  margin-top: 100px;
}
@media (min-width: 768px) {
  .advantage {
    margin-bottom: 50px;
  }
  .advantage:first-of-type {
    margin-top: 50px;
  }
}
@media (min-width: 768px) {
  .advantage {
    margin-bottom: 0;
  }
  .advantage:first-of-type {
    margin-top: 0;
  }
}
@media (min-width: 1200px) {
  .advantage {
    margin-bottom: 100px;
  }
}
@media (min-width: 768px) {
  .advantage--mb_min {
    margin-bottom: 20px;
  }
}
@media (min-width: 1200px) {
  .advantage--mb_min {
    margin-bottom: 100px;
  }
}
.advantage__image {
  position: relative;
  width: 100%;
  max-width: 330px;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}
@media (min-width: 992px) {
  .advantage__image--tall {
    top: 32px;
  }
}
@media (min-width: 768px) {
  .advantage__content {
    padding-left: 45px;
    margin-top: 0;
  }
  .advantage__content .add_issue_plugin__link {
    margin-bottom: 30px;
  }
}
.advantage__title {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: bold;
  color: #323343;
}
@media (min-width: 768px) {
  .advantage__title {
    font-size: 35px;
  }
}
.advantage__text {
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 1.4;
  font-weight: 300;
}
@media (min-width: 768px) {
  .advantage--reverse_mod {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }
  .advantage--reverse_mod .advantage__text {
    padding-right: 70px;
  }
  .advantage--reverse_mod .advantage__content {
    padding-left: 0;
  }
}

.advantage .image {
  padding: 0;
}
.advantage .image__card {
  position: relative;
  width: 215px;
  height: 130px;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#393947), to(#6a6a78));
  background-image: linear-gradient(to top, #393947, #6a6a78);
  border-radius: 5px;
  padding: 15px;
  margin-left: auto;
}
.advantage .image__card_active {
  -webkit-animation-name: card;
          animation-name: card;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
.advantage .image__time {
  font-size: 25px;
  color: #fff;
}
.advantage .image__text {
  width: 115px;
  height: 10px;
  background-color: #767681;
}
.advantage .image__text--first {
  margin-top: 10px;
}
.advantage .image__text--second {
  width: 75px;
  margin-top: 5px;
}
.advantage .image__text--third {
  width: 40px;
  height: 13px;
  background-color: #898991;
}
.advantage .image__text--third:not(:last-of-type) {
  margin-right: 5px;
}
.advantage .image__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 25px;
}
.advantage .image__plus {
  left: -10px;
  position: absolute;
  top: 95px;
}
.advantage .image__plus .part1 {
  width: 14px;
  height: 74px;
  background: #fa4a1f;
}
.advantage .image__plus .part2 {
  background: #fa4a1f;
  height: 14px;
  left: -30px;
  position: absolute;
  top: 30px;
  width: 74px;
}
.advantage .image__hand {
  position: absolute;
  top: 130px;
  left: -10px;
  height: 80px;
}
.advantage .image__tick {
  position: absolute;
  top: 10px;
  left: 140px;
  width: 0;
  height: 80px;
  overflow: hidden;
}
.advantage .image--plugin {
  padding-bottom: 80px;
}
@media (min-width: 992px) {
  .advantage .image--plugin {
    padding-bottom: 0;
  }
}
.advantage .image--first {
  padding-bottom: 30px;
}
@media (min-width: 992px) {
  .advantage .image--first {
    padding-bottom: 0;
  }
}
.advantage .image--first .clock {
  position: absolute;
  left: -80px;
  top: initial;
  bottom: -25px;
}
.advantage .image--first .clock__shadow {
  position: absolute;
  left: 80px;
  top: 2px;
  width: 42px;
  z-index: 0;
}
.advantage .image--first .clock__item {
  position: relative;
  z-index: 1;
}
.advantage .image--first .clock__arrows {
  position: absolute;
  width: 100px;
  height: 100px;
  left: 8px;
  top: 9px;
}
.advantage .image--first .clock__arrows_line {
  position: absolute;
  background-color: #ea3a24;
  width: 8px;
  height: 40px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  outline: 1px solid transparent;
  z-index: 2;
}
.advantage .image--first .clock__arrows_line--minute {
  left: 46px;
  bottom: 50px;
}
.advantage .image--first .clock__arrows_line--hour {
  height: 33px;
  left: 46px;
  bottom: 50px;
}
.advantage .image--second {
  padding-bottom: 60px;
}
@media (min-width: 992px) {
  .advantage .image--second {
    padding-bottom: 0;
  }
}
.advantage .image--second .image__card {
  opacity: 0;
  -webkit-transform: translateY(100px);
          transform: translateY(100px);
}
.advantage .image--second .image__clock_shadow {
  background-image: url(b9f80352bf07610b19f7ae36f1523df8.png);
  position: absolute;
  left: 78px;
  top: 4px;
  width: 42px;
  height: 82px;
  z-index: 1;
  opacity: 0;
}
.advantage .image--second .image__lens {
  position: absolute;
  top: 40px;
  left: 34px;
  width: 145px;
  height: 153px;
}
.advantage .image--second .image__lens_time {
  position: absolute;
  left: 23px;
  top: 42px;
  width: 42px;
  height: 82px;
  font-size: 27px;
  font-weight: 600;
  color: #eb3709;
  z-index: 6;
}
.advantage .image--second .image__svg {
  position: relative;
  z-index: 5;
}
.advantage .image--second .image__path {
  opacity: 0;
}
.advantage .image--third {
  margin-top: 32px;
}
@media (min-width: 992px) {
  .advantage .image--third {
    margin-top: 0;
  }
}
@media (max-width: 575.98px) {
  .advantage .image--third {
    margin-left: -18px;
  }
}
.advantage .image--third .image__card {
  position: absolute;
  z-index: 2;
  top: 32px;
  right: 15px;
  opacity: 0;
}
.advantage .image--third .image__animation_row {
  position: relative;
  width: 320px;
  height: 166px;
}
.advantage .image .animation_row__col {
  width: 86px;
  float: left;
}
.advantage .image .animation_row__task {
  position: relative;
  background-image: url(e9ccda74b880827b200d2879be8a4781.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 82px;
  height: 50px;
  margin: 4px 2px;
  opacity: 0.5;
}
.advantage .image .animation_row__task_time {
  color: #fff;
  font-size: 11px;
  padding: 5px 0 0 4px;
}
@media (max-width: 991.98px) {
  .advantage .image--fourth {
    margin-left: -18px;
  }
}
.advantage .image--fourth .image__wrapper {
  position: relative;
  width: 320px;
  height: 250px;
}
.advantage .image--fourth .image__coin {
  position: absolute;
  top: -100px;
  left: 113px;
  opacity: 0;
}
.advantage .image--fourth .image__coin_currency {
  display: block;
  position: absolute;
  top: 7px;
  left: 21px;
  color: #fff;
  font-size: 54px;
  line-height: 1.13;
}
.advantage .image--fourth .image__coin_white {
  position: absolute;
  background-image: url(0497e6c034be8d08b25862026c782607.svg);
  width: 76px;
  height: 39px;
  opacity: 0;
}
.advantage .image--fourth .image__clock {
  position: absolute;
  top: 128px;
  left: 16px;
  width: 100px;
  height: 100px;
}
.advantage .image--fourth .clock__arrow {
  position: absolute;
  background-color: #ea3a24;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  outline: 1px solid transparent;
  width: 8px;
  z-index: 3;
}
.advantage .image--fourth .clock__arrow--minute {
  height: 40px;
  bottom: 50px;
  left: 46px;
}
.advantage .image--fourth .clock__arrow--hour {
  height: 33px;
  bottom: 50px;
  left: 46px;
}
.advantage .image--fourth .clock object {
  position: absolute;
  top: -9px;
  left: -8px;
  z-index: 2;
}
.advantage .image .coin_white__7 {
  top: 139px;
  left: 112px;
}
.advantage .image .coin_white__6 {
  top: 124px;
  left: 112px;
}
.advantage .image .coin_white__11 {
  top: 162px;
  left: 162px;
}
.advantage .image .coin_white__5 {
  top: 109px;
  left: 112px;
}
.advantage .image .coin_white__10 {
  top: 147px;
  left: 162px;
}
.advantage .image .coin_white__9 {
  top: 132px;
  left: 162px;
}
.advantage .image .coin_white__4 {
  top: 94px;
  left: 112px;
}
.advantage .image .coin_white__3 {
  top: 79px;
  left: 112px;
}
.advantage .image .coin_white__8 {
  top: 117px;
  left: 162px;
}
.advantage .image .coin_white__2 {
  top: 64px;
  left: 112px;
}

.testimonials {
  position: relative;
  padding-top: 50px;
  padding-bottom: 80px;
}
@media (min-width: 768px) {
  .testimonials {
    padding-bottom: 40px;
  }
}
@media (min-width: 992px) {
  .testimonials {
    padding-bottom: 0;
    padding-top: 0;
  }
}
.testimonials__title {
  text-align: left;
  margin-bottom: 30px;
}
.testimonials__sir-trevor {
  padding-top: 0;
  padding-bottom: 0;
}
.testimonials__sir-trevor .testimonials__slider {
  margin-bottom: 40px;
}
.testimonials__slider {
  display: none;
}
.testimonials__slider.slick-initialized {
  display: block;
}
.testimonials .slick-current {
  z-index: 3;
}
.testimonials .slick-arrow {
  top: 25px;
  z-index: 4;
  opacity: 0.3;
}
.testimonials .slick-arrow:before {
  color: #999;
}
@media (min-width: 992px) {
  .testimonials .slick-arrow {
    top: 60px;
  }
}
.testimonials .slick-next {
  background-image: url(eaed32b32e718022ec2865cb4e317cd2.svg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
  width: 24px;
  height: 64px;
  right: 0;
}
.testimonials .slick-next:hover {
  opacity: 1;
  background-image: url(f02cbb304ee415bb835c0ee4acf62a1a.svg);
}
.testimonials .slick-next::before {
  content: "";
}
.testimonials .slick-prev {
  background-image: url(eaed32b32e718022ec2865cb4e317cd2.svg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  width: 24px;
  height: 64px;
  left: 0;
}
.testimonials .slick-prev:hover {
  opacity: 1;
  background-image: url(f02cbb304ee415bb835c0ee4acf62a1a.svg);
}
.testimonials .slick-prev::before {
  content: "";
}
.testimonials .slick-dots {
  right: 0;
  width: 100%;
}
@media (min-width: 992px) {
  .testimonials .slick-dots {
    right: 105px;
    max-width: 150px;
  }
}
.testimonials .slider_slick {
  font-family: "decimaNova", sans-serif;
  font-weight: 300;
}
.testimonials .slider_slick__slide {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  width: 100%;
  height: auto;
}
@media (min-width: 992px) {
  .testimonials .slider_slick__slide {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}
.testimonials .slider_slick__description {
  position: relative;
  max-height: 100%;
}
.testimonials .slider_slick__description p {
  margin-bottom: 20px;
  word-break: break-word;
}
.testimonials .slider_slick__description a {
  color: #ff4213;
  border-bottom: 1px solid #ff4213;
}
.testimonials .slider_slick__description a:hover {
  border-bottom: transparent;
}
@media (min-width: 992px) {
  .testimonials .slider_slick__description {
    width: 70%;
    margin-left: 65px;
    font-size: 24px;
    padding-top: 0;
    padding-right: 35px;
  }
}
.testimonials .slider_slick__description_text_wrapper {
  overflow: hidden;
  position: relative;
}
.testimonials .slider_slick__description_text_wrapper:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 10px;
  background: -webkit-gradient(linear, left bottom, left top, from(white), to(rgba(14, 0, 255, 0)));
  background: linear-gradient(0deg, white 0%, rgba(14, 0, 255, 0) 100%);
  bottom: 0;
  z-index: 1;
}
@media (min-width: 992px) {
  .testimonials .slider_slick__description_text_wrapper:before {
    content: none;
  }
}
.testimonials .slider_slick__description_text {
  width: 100%;
  font-size: 20px;
  padding-right: 0;
  line-height: 30px;
  padding-top: 30px;
  max-height: 310px;
  overflow: hidden;
}
@media (min-width: 992px) {
  .testimonials .slider_slick__description_text {
    max-height: 100%;
    padding-top: 0;
    padding-bottom: 0;
  }
}
.testimonials .slider_slick__description_button {
  position: absolute;
  display: none;
  bottom: 6px;
  z-index: 2;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.testimonials .slider_slick__description_more {
  position: absolute;
  display: none;
  bottom: 6px;
  z-index: 2;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  color: #323343;
  font-size: 18px;
  font-weight: 400;
  border: none;
  border-bottom: 1px dashed #323343;
  cursor: pointer;
}
.testimonials .slider_slick__description.show_more {
  padding-bottom: 40px;
}
.testimonials .slider_slick__description.show_more .slider_slick__description_more {
  display: block;
}
@media (min-width: 992px) {
  .testimonials .slider_slick__description.show_more .slider_slick__description_more {
    display: none;
  }
}
.testimonials .slider_slick_dots {
  position: relative;
}
.testimonials .slider_slick_dots li {
  width: 9px;
  height: 9px;
}
.testimonials .slider_slick_dots li button {
  width: 9px;
  height: 9px;
}
.testimonials .slider_slick_dots li button::before {
  color: #ef4115;
  top: -5px;
  opacity: 1;
  font-size: 20px;
  width: 9px;
  height: 9px;
}
.testimonials .slider_slick_dots .slick-active button::before {
  opacity: 1;
  font-size: 0;
  top: 1px;
  border: 2px solid #ef4115;
  border-radius: 50%;
  width: 8px;
  height: 8px;
}
.testimonials .slider_slick_company {
  width: 100%;
  color: #b8b8b8;
  text-align: center;
}
@media (min-width: 992px) {
  .testimonials .slider_slick_company {
    width: 20%;
    margin-right: 65px;
    text-align: left;
  }
}
.testimonials .slider_slick_company__img {
  width: 180px;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 992px) {
  .testimonials .slider_slick_company__img {
    margin-left: inherit;
    margin-right: inherit;
  }
}
.testimonials .slider_slick_company__title {
  font-size: 24px;
  line-height: 30px;
  word-break: break-word;
}
.testimonials .slider_slick_company__description {
  font-size: 16px;
  line-height: 25px;
  word-break: break-word;
}
.testimonials__button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
  padding: 17px 38px;
  font-size: 20px;
}
@media (min-width: 992px) {
  .testimonials__button {
    padding: 23px 71px;
    font-size: 24px;
    margin-bottom: 120px;
  }
}

.features {
  padding-top: 60px;
  margin-bottom: 60px;
}
@media (min-width: 768px) {
  .features {
    margin-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .features {
    margin-bottom: 40px;
  }
}
.features__title {
  margin-bottom: 15px;
  text-align: center;
  font-size: 38px;
  color: #ff4213;
}
@media (min-width: 768px) {
  .features__title {
    margin-bottom: 40px;
    font-size: 44px;
  }
}
@media (min-width: 992px) {
  .features__title {
    margin-bottom: 70px;
  }
}
.features__list {
  list-style: none;
}
.features__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-bottom: 10px;
}
.features__item:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .features__item {
    margin-bottom: 40px;
  }
}
@media (min-width: 992px) {
  .features__item {
    margin-bottom: 70px;
  }
}
.features__icon {
  width: 25px;
  min-width: 25px;
  max-height: 20px;
  margin-top: 5px;
  margin-right: 10px;
}
.features__text {
  font-size: 20px;
  font-weight: 300;
  color: #323343;
}
@media (min-width: 768px) {
  .features__text {
    font-size: 24px;
  }
}

.footer {
  display: block;
  width: 100%;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  border-top: 1px solid #dadada;
  padding-top: 40px;
  padding-bottom: 40px;
}
.footer__forforce {
  display: block;
  background-color: #292929;
  width: 140px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  -webkit-transition: background-color 0.15s ease-out;
  transition: background-color 0.15s ease-out;
}
.footer__forforce:hover {
  background-color: #ef4115;
}
.footer__company {
  font-family: "Arial", "Tahoma", sans-serif;
  font-size: 12px;
  color: #9fa0b1;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .footer__company {
    text-align: left;
    margin: 0 0 0 20px;
  }
}
.footer_row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (min-width: 768px) {
  .footer_row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}
@media (min-width: 768px) {
  .footer_row--second {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
}
.footer__hr {
  border: none;
  border-top: 1px solid #dadada;
}
.footer__nav {
  margin-bottom: 20px;
  text-align: center;
}
@media (min-width: 768px) {
  .footer__nav {
    text-align: left;
  }
}
@media (min-width: 992px) {
  .footer__nav {
    margin-bottom: 0;
  }
}
.footer__nav_title {
  color: #898989;
  font-size: 20px;
  margin-bottom: 10px;
}
.footer__nav_list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 10px;
}
.footer__nav_list_item {
  font-size: 18px;
  color: #292929;
  -webkit-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
.footer__nav_list_item:hover {
  color: #ff4213;
}

.price_list {
  display: block;
}
@media (min-width: 768px) {
  .price_list {
    padding-bottom: 48px;
  }
}
.price_list__sir-trevor {
  padding-bottom: 0;
  margin-bottom: 40px;
}
.price_list_wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (min-width: 768px) {
  .price_list_wrap {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}
.price_list_card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-bottom: 80px;
  width: 100%;
}
@media (min-width: 768px) {
  .price_list_card {
    margin-bottom: 0;
    width: 33.333%;
  }
}
.price_list_card_header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-height: 20%;
}
.price_list_card_bottom {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.price_list_card_title {
  font-weight: 100;
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
}
@media (min-width: 992px) {
  .price_list_card_title {
    font-size: 24px;
  }
}
.price_list_card_title__box {
  position: relative;
  display: inline;
  font-weight: bold;
  color: #ef4115;
  cursor: pointer;
  border-bottom: 1px dotted #ef4115;
  z-index: 2;
}
.price_list_card_title__box:hover {
  border-bottom: none;
}
.price_list_card_title__href {
  color: #ef4115;
  position: relative;
  padding: 0;
  background-color: transparent;
  border: none;
}
.price_list_card__subtitle {
  font-weight: 100;
  font-size: 14px;
  text-align: center;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
@media (min-width: 768px) {
  .price_list_card__subtitle {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (min-width: 992px) {
  .price_list_card__subtitle {
    padding-left: 50px;
    padding-right: 50px;
  }
}
.price_list_card__icon {
  display: block;
  width: 100px;
  height: 95px;
  -o-object-position: center;
     object-position: center;
  -o-object-fit: contain;
     object-fit: contain;
  margin: 20px auto 15px;
}
.price_list_card__price {
  font-weight: bold;
  font-size: 36px;
  color: #ef4115;
  text-align: center;
}
.price_list_card__button {
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 100;
  font-size: 20px;
  padding: 17px 30px;
  margin: 20px auto 42px;
}
@media (min-width: 768px) {
  .price_list_card__button {
    font-size: 24px;
  }
}
.price_list_card__list {
  display: block;
}
.price_list_card__list ul {
  margin: 0 !important;
}
.price_list_card__list p {
  font-family: "Arial", "Helvetica", sans-serif;
  font-size: 11px;
  color: #557;
  padding-left: 30px;
}

.iframe {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 30px;
}
@media (min-width: 768px) {
  .iframe {
    margin-top: 0;
  }
}
.iframe iframe {
  display: block;
  border: none;
  overflow: hidden;
  visibility: visible;
}

.our_services {
  display: block;
  padding-top: 60px;
  padding-bottom: 85px;
}
.our_services__row {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.our_services__item {
  max-width: 290px;
}
.our_services__item:not(:last-of-type) {
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  .our_services__item {
    max-width: 100%;
  }
  .our_services__item:not(:last-of-type) {
    margin-bottom: 0;
  }
}
.our_services__img {
  overflow: hidden;
  width: 64px;
  height: 64px;
  margin-left: auto;
  margin-right: auto;
  fill: #ef4115;
}
@media (min-width: 768px) {
  .our_services__img {
    margin-left: inherit;
    margin-right: inherit;
  }
}
.our_services__img img {
  -o-object-position: bottom;
     object-position: bottom;
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
.our_services__title {
  font-family: "decimaNova", sans-serif;
  font-weight: bold;
  font-size: 24px;
  color: #333;
  margin-top: 23px;
  margin-bottom: 8px;
  text-align: center;
}
@media (min-width: 768px) {
  .our_services__title {
    text-align: left;
  }
}
.our_services .list {
  list-style-type: none;
}
.our_services .list__item {
  position: relative;
  font-family: "decimaNova", sans-serif;
  font-weight: 300;
  font-size: 18px;
  color: #557;
  line-height: 22px;
  padding-left: 15px;
}
.our_services .list__item:not(:last-of-type) {
  margin-bottom: 6px;
}
.our_services .list__item::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
  background-color: #ef4115;
  border-radius: 50%;
  width: 5px;
  height: 5px;
}
@media (min-width: 768px) {
  .our_services .list__item {
    text-align: left;
  }
}

.faq {
  padding-bottom: 75px;
}
.faq__sir-trevor {
  padding-bottom: 0;
  margin-bottom: 40px;
}
.faq_menu {
  list-style-type: none;
  margin-bottom: 40px;
  margin-top: 20px;
}
@media (min-width: 768px) {
  .faq_menu {
    margin-bottom: 20px;
  }
}
.faq_menu__item:not(:last-of-type) {
  margin-bottom: 17px;
}
.faq_menu__link {
  display: inline-block;
  font-weight: 100;
  font-size: 20px;
  line-height: 23px;
  color: #323343;
  background-color: transparent;
  padding: 0;
  border: none;
  border-bottom: 1px dashed #323343;
  cursor: pointer;
  text-align: left;
}
.faq_menu__link:hover {
  border-bottom-color: transparent;
}
.faq_menu__link--active {
  color: #ef4115;
  border-bottom-color: transparent;
}
.faq_accordion {
  display: block;
}
.faq_accordion__row {
  position: relative;
}
.faq_accordion__row:not(:last-of-type) {
  border-bottom: 1px solid #e6e6e6;
}
.faq_accordion__row:not(:first-of-type) .faq_accordion__item {
  padding-top: 17px;
}
.faq_accordion__link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.faq_accordion__item {
  padding-bottom: 4px;
  display: none;
}
.faq_accordion__title {
  display: block;
  position: relative;
  font-size: 20px;
  padding-right: 15px;
  color: #333;
  margin-bottom: 15px !important;
  cursor: pointer;
}
.faq_accordion__title:hover {
  color: #ef4115;
}
.faq_accordion__title:hover::after {
  background-image: url(3edc005146c4dfa35463c9c4a66a3a41.svg);
}
.faq_accordion__title::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: 8px;
  background-image: url(d2939f87103f19f8b2477a69df5dac22.svg);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  width: 13px;
  height: 7px;
  -webkit-transition: 150ms;
  transition: 150ms;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.faq_accordion__title--active {
  color: #ef4115;
}
.faq_accordion__title--active::after {
  background-image: url(3edc005146c4dfa35463c9c4a66a3a41.svg);
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.faq_accordion__button {
  padding: 0;
  background-color: transparent;
  border: none;
  font-weight: 100;
}
.faq .sidebar {
  will-change: min-height;
}
.faq .sidebar__inner {
  padding-bottom: 40px;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
  /* For browsers don't support translate3d. */
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  will-change: position, transform;
}
.faq .sidebar__inner {
  padding-bottom: 0;
}
@media (min-width: 768px) {
  .faq .sidebar__inner {
    padding-bottom: 40px;
  }
}

.typography .faq_accordion__title {
  display: block;
  position: relative;
  font-size: 20px;
  padding-right: 15px;
  color: #333;
  margin-bottom: 15px !important;
  margin-top: 20px;
  cursor: pointer;
}
.typography .faq_accordion a {
  text-decoration: none;
}
.typography .faq_accordion a.collapsed h3:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transition: 150ms;
  transition: 150ms;
}
.typography .faq_accordion a h3:after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  -webkit-transition: 150ms;
  transition: 150ms;
}
.typography .faq_accordion__title {
  margin-top: 20px !important;
  font-size: 20px;
  line-height: 1.15;
}
.typography .faq_accordion__title:hover {
  color: #ff4b1e;
  -webkit-transition: 150ms;
  transition: 150ms;
}

.menu_list {
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
}
.menu_list__title {
  color: #fff;
  font-weight: 300;
  font-size: 18px;
  margin-top: 15px;
  text-align: center;
}
@media (min-width: 768px) {
  .menu_list__title {
    font-size: 20px;
    text-align: left;
  }
}
.menu_list__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  list-style-type: none;
}
@media (min-width: 768px) {
  .menu_list__list {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}
.menu_list__list_item {
  width: 100%;
  font-size: 20px;
  line-height: 1.6em;
  color: #fff;
  text-align: center;
}
@media (min-width: 768px) {
  .menu_list__list_item {
    font-size: 27px;
    width: 50%;
    text-align: left;
  }
}
.menu_list__list_link {
  color: #fff;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.menu_list__list_link:hover {
  color: #ff4213;
}

.modals {
  display: contents;
}
.modals #server_response_container {
  display: none;
  position: absolute;
  background: #ffffff;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.modals #server_response_container.show {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.modals .modal_container--sign_up {
  width: 816px;
}
@media screen and (max-width: 700px) {
  .modals .modal_container--sign_up {
    height: 535px;
  }
}
.modals .modal_container__account {
  display: table;
  margin: 20px auto 15px;
}

.modal_container__forgot {
  display: table;
  margin-top: 15px;
  margin-bottom: 35px;
  color: #ef4115;
  border-bottom: 1px dotted #ef4115;
}
.modal_container__forgot:hover {
  border-bottom-color: transparent;
}

.modals .modal_feedback {
  position: fixed;
  z-index: 10;
  top: calc(40% + 76px);
  left: -10px;
  color: #fff;
  padding: 15px 10px 5px;
  -webkit-box-shadow: rgba(255, 255, 255, 0.247059) 1px 1px 1px inset, rgba(0, 0, 0, 0.498039) 0 1px 3px;
          box-shadow: rgba(255, 255, 255, 0.247059) 1px 1px 1px inset, rgba(0, 0, 0, 0.498039) 0 1px 3px;
  background-color: #292929;
  border: 1px solid #fff;
  border-top: 0;
  -webkit-transform-origin: left top;
          transform-origin: left top;
  -webkit-transform: rotate(-90deg) translateX(-50%);
          transform: rotate(-90deg) translateX(-50%);
  cursor: pointer;
  -webkit-transition: 0.3s ease-out background-color, 0.3s left;
  transition: 0.3s ease-out background-color, 0.3s left;
}
@media (min-width: 768px) {
  .modals .modal_feedback {
    top: 40%;
  }
}
.modals .modal_feedback:hover {
  left: -5px;
  background-color: #ff4213;
}

.js--modal_new_password.active .modal_container__guts {
  min-height: 210px;
}
.js--modal_new_password.active .modal_container__guts .modal_container__title,
.js--modal_new_password.active .modal_container__guts form {
  opacity: 0;
  height: 0;
  overflow: hidden;
  -webkit-transition: 300ms;
  transition: 300ms;
}
.js--modal_new_password.active .modal_container__guts.show_fields {
  min-height: auto;
}
.js--modal_new_password.active .modal_container__guts.show_fields .modal_container__close,
.js--modal_new_password.active .modal_container__guts.show_fields .modal_container__title,
.js--modal_new_password.active .modal_container__guts.show_fields form {
  opacity: 1;
  height: auto;
}
.js--modal_new_password.active .modal_container__guts.show_fields .loader {
  display: none;
}
.js--modal_new_password.active .modal_container__guts .loader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.js--modal_new_password.active .modal_container__guts .loader.hide {
  display: none;
}
.js--modal_new_password.active .modal_container__guts .response_messages {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
  font-size: 28px;
  opacity: 0;
  -webkit-transition: 300ms;
  transition: 300ms;
  padding: 0 40px;
}
.js--modal_new_password.active .modal_container__guts .response_messages.show {
  opacity: 1;
}

.modal_container--thanks .modal_container__button {
  display: none;
}
.modal_container--thanks .modal_container__button.show {
  display: block;
}

.ui-widget-content {
  border-radius: 4px;
  border: 1px solid #b4b4b4;
  background: #fff;
  color: #7d7d7d;
  z-index: 200000 !important;
  line-height: 18px;
  font-size: 14px;
}

.ui-datepicker td {
  width: 40px;
  height: 40px;
}
.ui-datepicker td:hover {
  background-color: #f5f5f5;
  border-radius: 50%;
}

.ui-datepicker td a {
  color: #7d7d7d;
  text-align: center;
}

.ui-datepicker {
  width: 22em;
  height: 23em;
}

.ui-datepicker table {
  font-size: 1em;
}

.ui-datepicker-current-day {
  background-color: #a7b1b8;
  border-radius: 50%;
}
.ui-datepicker-current-day:hover {
  background-color: #444;
}
.ui-datepicker-current-day .ui-state-active {
  color: #fff;
}

.ui-datepicker .ui-datepicker-next {
  background-image: url(5aefcb869d72ae8a145cfd9b7511ca35.svg);
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
  right: 15px;
  top: 10px;
}

.ui-datepicker .ui-datepicker-prev {
  background-image: url(5aefcb869d72ae8a145cfd9b7511ca35.svg);
  background-size: contain;
  background-position: center;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  width: 16px;
  height: 16px;
  left: 15px;
  top: 10px;
}

.demo {
  position: relative;
  /* login timer*/
  /* login timer end*/
  /*======================= scroll style ========================*/
  /*======================== rmclinet frame ============================*/
  /*======================== rmclinet frame ============================*/
}
@media (max-width: 991.98px) {
  .demo {
    height: 460px;
    pointer-events: none;
  }
}
.demo ul {
  list-style: none;
}
.demo .fs0 {
  font-size: 0 !important;
}
.demo .fs10 {
  font-size: 10px !important;
}
.demo .fs12 {
  font-size: 12px !important;
}
.demo .fs13 {
  font-size: 13px !important;
}
.demo .fs14 {
  font-size: 14px !important;
}
.demo .fs20 {
  font-size: 20px !important;
}
.demo .lh0 {
  line-height: 0 !important;
}
.demo .lh12 {
  line-height: 12px !important;
}
.demo .block {
  display: block !important;
}
.demo .inline-block {
  display: inline-block !important;
}
.demo .none {
  display: none !important;
}
.demo .clear {
  clear: both;
  height: 0;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
  display: block;
}
.demo .fleft {
  float: left !important;
}
.demo .fright {
  float: right !important;
}
.demo .tac {
  text-align: center !important;
}
.demo .cp {
  cursor: pointer !important;
}
.demo .fwb {
  font-weight: 700 !important;
}
.demo .pt1 {
  padding-top: 1px !important;
}
.demo .pt8 {
  padding-top: 8px !important;
}
.demo .pt9 {
  padding-top: 9px !important;
}
.demo .pb1 {
  padding-bottom: 1px !important;
}
.demo .pb15 {
  padding-bottom: 15px !important;
}
.demo .pl50 {
  padding-left: 50px !important;
}
.demo .mt1 {
  margin-top: 1px !important;
}
.demo .mt11 {
  margin-top: 11px !important;
}
.demo .mt14 {
  margin-top: 14px !important;
}
.demo .mt20 {
  margin-top: 20px !important;
}
.demo .mt30 {
  margin-top: 30px !important;
}
.demo .mr20 {
  margin-right: 20px !important;
}
.demo .mb5 {
  margin-bottom: 5px !important;
}
.demo .mb10 {
  margin-bottom: 10px !important;
}
.demo .mb11 {
  margin-bottom: 11px !important;
}
.demo .mb15 {
  margin-bottom: 15px !important;
}
.demo .mb19 {
  margin-bottom: 19px !important;
}
.demo .mb20 {
  margin-bottom: 20px !important;
}
.demo .mb29 {
  margin-bottom: 29px !important;
}
.demo .ml0 {
  margin-left: 0 !important;
}
.demo .ml2 {
  margin-left: 2px !important;
}
.demo .ml4 {
  margin-left: 4px !important;
}
.demo .ml5 {
  margin-left: 5px !important;
}
.demo .ml7 {
  margin-left: 7px !important;
}
.demo .ml10 {
  margin-left: 10px !important;
}
.demo .ml16 {
  margin-left: 16px !important;
}
.demo .top5 {
  top: 5px !important;
}
.demo .top10 {
  top: 10px !important;
}
.demo .topm2 {
  top: -2px !important;
}
.demo .w100p {
  width: 100% !important;
}
.demo .clearfix:after {
  clear: both;
  content: " ";
  display: block;
  font-size: 0;
  height: 0;
  visibility: hidden;
}
.demo .mdl-ripple {
  background: white;
}
.demo .rm_client_timer_box {
  right: 0;
  width: 425px;
  height: 520px;
  font-family: "decimaNovaPro", sans-serif;
  z-index: 6;
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  -webkit-box-shadow: 0 8px 30px rgba(0, 0, 0, 0.8);
          box-shadow: 0 8px 30px rgba(0, 0, 0, 0.8);
  border-radius: 9px 9px 0 0;
  background: #000;
}
.demo .js_client_frame .rm_client_timer_box {
  width: 300px;
}
.demo .rm_client_timer_box.show {
  z-index: 50000;
}
.demo .rm_client_timer_box:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 106;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  /*background: #D52816;*/
  background: #000;
  visibility: hidden;
}
.demo .rm_client_timer_box.hide:hover:after {
  content: "";
  position: absolute;
  top: 0;
  left: 100px;
  width: 100%;
  height: 100%;
  background: url(71a50dbba44c78128b221b7df7bb51f1.png) repeat 0 0;
  z-index: 51;
  visibility: visible;
  opacity: 0.5;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.demo .rm_client_timer_box.hide.show_mask:after {
  visibility: visible;
  opacity: 0.5;
}
.demo .rm_client_timer_box.popup_open:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 100;
  opacity: 0.2;
  visibility: visible;
}
.demo .main:before {
  border-bottom: 174px solid #FFFFFF;
  border-left: 709px solid transparent;
  bottom: -227px;
  content: "";
  height: 0;
  left: -304px;
  position: absolute;
  width: 0;
  z-index: 41;
}
.demo .draggable_block {
  position: absolute;
  top: 0;
  left: 0;
  width: 425px;
  height: 23px;
  z-index: 50;
}
.demo .js_client_frame .draggable_block {
  display: none;
}
.demo .rm_client_timer_box.hide .rm_client_timer_login {
  z-index: 40;
}
.demo .btn_hide_client {
  position: absolute;
  left: 8px;
  top: 5px;
  width: 60px;
  height: 15px;
  z-index: 51;
  cursor: pointer;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.demo .hide .btn_hide_client, .demo .popup_open .btn_hide_client {
  z-index: 51;
}
.demo .hide .btn_hide_client_bg {
  z-index: 40;
}
.demo .btn_hide_client:hover {
  background-position: 0 -41px;
}
.demo .rm_client_timer_box.show .btn_hide_client {
  display: block;
}
.demo .rm_client_timer_login {
  border: 1px solid rgba(0, 0, 0, 0.3);
  position: absolute;
  width: 425px;
  height: 425px;
  background-color: #444;
  top: 22px;
  /*padding-top: 22px;*/
  left: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  opacity: 0;
  visibility: hidden;
}
.demo .js_client_frame .rm_client_timer_login {
  width: 300px;
}
.demo .rm_client_timer_login.show {
  visibility: visible;
  z-index: 110;
}
.demo .rm_login_cont {
  /*padding: 23px 22px;*/
  width: 423px;
  position: relative;
  height: 425px;
  background: #444;
  color: #cccccc;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "decimaNovaPro", sans-serif;
  overflow: hidden;
}
.demo .timer_log_field {
  position: relative;
  width: 252px;
}
.demo .timer_log_field:after {
  position: absolute;
  width: 50px;
  right: 1px;
  top: 1px;
  height: 27px;
  content: "";
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzQ0NDQ0NCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM0NDQ0NDQiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -webkit-gradient(linear, left top, right top, from(rgba(68, 68, 68, 0)), to(#444444));
  background: linear-gradient(to right, rgba(68, 68, 68, 0) 0%, #444444 100%);
}
.demo .settings_item {
  margin-bottom: 9px;
}
.demo .settings_item__name {
  float: left;
  font-size: 16px;
  width: 100px;
  padding-top: 9px;
}
.demo .settings_textfield {
  padding: 0;
  width: 278px;
}
.demo .settings_textfield.is-focused .hint_input {
  visibility: visible;
  opacity: 1;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.demo .settings_textfield__input {
  background-color: #4d5357;
  border-bottom: 1px solid rgba(255, 255, 255, 0.77);
  border-radius: 2px 2px 0 0;
  padding: 8px 5px;
}
.demo .settings_textfield__label {
  color: rgba(0, 0, 0, 0.26);
  top: 0;
  bottom: 0;
}
.demo .settings_textfield__label:after {
  background-color: rgba(255, 255, 255, 0.5);
  bottom: 0;
}
.demo .settings_textfield .hint_input {
  position: absolute;
  right: -15px;
  top: 7px;
  color: #ccdae3;
  text-decoration: none;
  font-family: "decima nova bold", sans-serif;
  font-size: 17px;
  opacity: 1;
  visibility: hidden;
  -webkit-transition: all 0.2s ease-out 0.5s;
  transition: all 0.2s ease-out 0.5s;
}
.demo .timer_log_field input {
  background-color: transparent;
  height: 29px;
  width: 100%;
  border: 1px solid #7c7c7c;
  padding: 0 7px;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "decima nova", sans-serif;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.85);
}
.demo .timer_log_field input:focus {
  border: 1px solid #b4b4b4;
  background-color: #4f4f4f;
}
.demo .settings_textfield input:focus + .hint_input {
  visibility: visible;
  opacity: 1;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.demo .settings_textfield .hint_input:hover {
  color: #ff3f27;
}
.demo .btn_log_gray {
  background: #7c7c7c;
  height: 33px;
  border-radius: 3px;
  line-height: 33px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  width: 120px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  font-family: "decimaNovaPro", sans-serif;
  padding: 0 5px;
}
.demo .settings_checkbox {
  margin-bottom: 14px;
  display: block;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.demo .settings_checkbox_item.is-upgraded {
  padding-left: 35px;
  width: unset;
  margin-left: auto;
}
.demo .settings_checkbox_item {
  float: right;
}
.demo .settings_checkbox_item.is-checked .mdl-switch__thumb {
  background: white;
}
.demo .settings_checkbox_item.is-checked .mdl-switch__track {
  background: rgba(255, 255, 255, 0.5);
}
.demo .settings_buttons {
  margin-top: 34px;
  margin-left: 20%;
}
.demo .btn_log_gray:hover {
  background-color: #545454;
}
.demo .btn_load_file {
  width: 140px !important;
}
.demo .file_text {
  display: inline-block;
  font-size: 13px;
  margin-left: 10px;
}
.demo .attaching_file {
  height: 40px;
  line-height: 40px;
  margin-bottom: 5px;
  vertical-align: middle;
}
.demo .file {
  display: inline-block;
  padding-left: 30px;
  background: url(95040ede6221d204e2850dd1ec318504.png) no-repeat 13px center;
}
.demo .remove_file {
  float: right;
  height: 20px;
  width: 22px;
  margin-top: 6px;
  margin-left: 6px;
  cursor: pointer;
  background: url(aeb630a1addc0d37f264fdd256d24cce.png) no-repeat;
}
.demo .file_input {
  border: 1px solid #7c7c7c;
  border-radius: 3px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: block;
  float: right;
  font-size: 13px;
  height: 30px;
  line-height: 14px;
  width: 290px;
  background: #444444;
  padding: 0 10px;
  color: #ffffff;
}
.demo .file_input:focus {
  background: #5b5b5b;
}
.demo .rm_log_checkbox {
  width: 35px;
  height: 20px;
  border-radius: 2px;
  background-color: #7e7e7e;
  position: relative;
  overflow: hidden;
  padding: 2px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.demo .active .rm_log_checkbox {
  background-color: #d7d7d7;
}
.demo .rm_log_checkbox input {
  opacity: 0;
  margin: 0;
  width: 35px;
  height: 20px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  padding: 0;
  cursor: pointer;
}
.demo .rm_log_checkbox .checkbox_bg {
  width: 16px;
  height: 16px;
  display: block;
  position: absolute;
  top: 2px;
  left: 2px;
  z-index: 1;
  background: #444;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.demo .active .checkbox_bg {
  left: 17px;
}
.demo .rm_log_checkbox .checker, .demo .rm_log_checkbox .checker span {
  background: none !important;
  border: none !important;
}
.demo .checkbox_label label {
  display: inline-block;
  padding-top: 4px;
}
.demo .checkbox_label {
  /*text-shadow: -1px 0 0 #fafade;*/
  line-height: 14px;
  font-size: 16px;
  color: #7d7d7d;
  -webkit-transition: color 0.2s ease-out;
  transition: color 0.2s ease-out;
}
.demo .active .checkbox_label {
  color: rgba(255, 255, 255, 0.75);
}
.demo .rm_client_timer {
  position: absolute;
  width: 425px;
  height: 500px;
  background: #444444;
  top: 22px;
  left: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0px 4px 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  z-index: 100;
}
.demo .js_client_frame .rm_client_timer.js_rm_client_timer {
  width: 300px;
}
.demo .rm_client_timer.notfound:before {
  content: "Not found";
  width: 100%;
  height: 100px;
  position: absolute;
  text-align: center;
  line-height: 20px;
  font-size: 20px;
  color: #eaeaea;
  top: 70%;
  left: 0;
  margin-top: -10px;
  z-index: 1;
}
.demo .rm_help_link {
  color: #fff;
  display: block;
  position: absolute;
  right: 7px;
  top: -19px;
  font-size: 14px;
  text-decoration: none;
}
.demo .rm_help_link:hover {
  color: #f88883;
}
.demo .btn_hide_client_bg {
  content: "";
  position: absolute;
  color: #222222;
  font-size: 16px;
  top: 0px;
  left: 0px;
  border-radius: 7px 7px 0 0;
  background: url(817f9fe35ded76f16cc7ee8578f192fe.png) 0 0 no-repeat;
  width: 100%;
  text-align: center;
  line-height: 22px;
  height: 22px;
  z-index: 50;
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #b4b4b4;
  -webkit-box-shadow: 0 1px 0 0 #f6f6f6 inset;
          box-shadow: 0 1px 0 0 #f6f6f6 inset;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background: #eaeaea;
  /* Old browsers */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2VhZWFlYSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNkNmQ2ZDYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  /* FF3.6+ */
  /* Chrome,Safari4+ */
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE10+ */
  background: -webkit-gradient(linear, left top, left bottom, from(#eaeaea), to(#d6d6d6));
  background: linear-gradient(to bottom, #eaeaea 0%, #d6d6d6 100%);
  /* W3C */
  /*filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eaeaea', endColorstr='#d6d6d6',GradientType=0 ); */
  /* IE6-8 */
}
.demo .btn_circle_hide_client {
  display: block;
  width: 12px;
  height: 12px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 1px solid;
  border-radius: 6px;
  float: left;
  margin-right: 8px;
}
.demo .btn_hide_client:hover .btn_circle_hide_client {
  background-image: url(48543fab65226f00f0bd3c5f5575452d.png);
}
.demo .btn_hide_client:hover .btn_circle_hide_client.red {
  background-position: -1px -1px;
}
.demo .btn_hide_client:hover .btn_circle_hide_client.yellow {
  background-position: -21px -1px;
}
.demo .btn_hide_client:hover .btn_circle_hide_client.green {
  background-position: -41px -1px;
}
.demo .btn_circle_hide_client.red {
  background: #fc5858;
  border-color: #de3a3e;
}
.demo .btn_circle_hide_client.yellow {
  background: #febe30;
  border-color: #dea11a;
}
.demo .btn_circle_hide_client.green {
  background: #36cd40;
  border-color: #2aaf28;
}
.demo .control_panel {
  padding: 5px 0 0 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 40px;
  margin-bottom: 5px;
}
.demo .control_panel_icon {
  line-height: unset;
}
.demo .rm_client_timer_login .control_panel {
  margin: 0 4px 6px;
}
.demo .control_panel .user_name {
  font-size: 13px;
  color: #9ca09f;
  cursor: pointer;
  display: inline-block;
  font-family: "decimaNovaPro", sans-serif;
}
.demo .js_popup_time {
  font-size: 20px;
}
.demo .rm_timer_search {
  padding: 0 5px;
}
.demo .rm_timer_search .btn_clear_inp, .demo .btn_close_filter {
  width: 25px;
  height: 25px;
  display: block;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 50px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  fill: #a7b1b8;
}
.demo .rm_timer_search .btn_clear_inp:hover, .demo .btn_close_filter:hover {
  background-position: -133px -26px;
}
.demo .rm_timer_search .timer_field {
  color: #fff;
  border-radius: 2px;
  background: #4d5357;
  border: none;
  width: 100%;
  height: 33px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 20px 0 4px;
  border-bottom: 1px solid #a7b1b8;
}
.demo .user_button {
  padding: 0 5px;
}
.demo .user_button .jira_icon {
  width: 16px;
  height: 16px;
  fill: #a7b1b8;
}
.demo .user_button .redmine_icon {
  width: 20px;
  height: 14px;
  fill: #a7b1b8;
  padding-top: 3px;
}
.demo .user_button:active {
  background-color: rgba(156, 156, 156, 0.4);
}
.demo .user_button:hover {
  background-color: rgba(103, 102, 102, 0.2);
}
.demo .menu_icon {
  width: 20px;
  height: 20px;
  fill: #a7b1b8;
}
.demo .menu_icon:hover {
  fill: #fff;
}
.demo .rm_client_timer .task {
  padding: 12px 12px 14px;
  border-radius: 1px;
  position: relative;
  margin-top: 4px;
  color: #010101;
  cursor: default;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.demo .rm_client_timer .task.non_search:not(.current):after {
  content: "";
  position: absolute;
  width: 101%;
  height: 102%;
  background: #333;
  opacity: 0.75;
  top: -1px;
  left: -1px;
}
.demo .task .time_small {
  position: absolute;
  right: 10px;
  top: 18px;
  font-size: 11px;
  width: 28px;
  overflow: hidden;
}
.demo .task.current .time_small {
  width: auto;
}
.demo .task .time_small_all {
  position: absolute;
  right: 7px;
  top: 31px;
  font-size: 11px;
  opacity: 1;
}
.demo .task.current .time_small_all {
  color: #fff;
}
.demo .task .time_large {
  font-size: 40px;
  font-family: "decimaNovaPro", sans-serif;
  display: none;
}
.demo .task .task_name {
  font-size: 20px;
  width: 90%;
}
.demo .task .task_number {
  font-size: 12px;
  margin-bottom: 15px;
  width: 80%;
}
.demo .task .timer_comment {
  position: absolute;
  left: 4px;
  top: 4px;
  background: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 282px;
  border-radius: 2px;
  padding: 5px 5px 22px 5px;
  overflow: hidden;
  cursor: default;
  font-size: 0;
  line-height: 0;
  display: none;
}
.demo .task .timer_comment .comment_field {
  height: 95px;
  margin: 0;
  padding: 0;
  border: none;
  /*padding-right: 40px;*/
  width: 274px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: auto;
  font-family: "decima nova", sans-serif;
  resize: none;
  font-size: 12px;
}
.demo .task .timer_comment .btn_comment_task {
  position: absolute;
  right: 6px;
  bottom: 2px;
  color: #333;
  cursor: pointer;
  display: block;
}
.demo .task .timer_comment .btn_comment_task:hover {
  color: #666;
}
.demo .timer_comment .btn_comment_task .icon_comment_gray {
  background: url(817f9fe35ded76f16cc7ee8578f192fe.png) no-repeat -255px -24px;
  height: 18px;
  width: 21px;
  display: inline-block;
}
.demo .timer_comment .btn_comment_task:hover .icon_comment_gray {
  background-position: -278px -24px;
}
.demo .task .task_descript {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 12px;
  max-height: 36px;
}
.demo .task_btn {
  height: 32px;
  width: 32px;
  position: absolute;
  cursor: pointer;
  display: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.demo .task_btn.mdl-ripple {
  background: black;
}
.demo .task_btn:hover:after {
  opacity: 0.3;
}
.demo .current .task_btn {
  border-color: #ffffff !important;
}
.demo .current .task_btn:after {
  background: #ffffff !important;
}
.demo .current .btn_play {
  right: -2px;
  bottom: 0px;
}
.demo .current .btn_stop {
  right: -2px;
  bottom: 0px;
}
.demo .current .btn_comment {
  right: 33px;
  bottom: 0px;
}
.demo .btn_comment {
  right: 45px;
  bottom: 10px;
}
.demo .btn_play {
  right: 10px;
  bottom: 10px;
}
.demo .btn_stop {
  right: 10px;
  bottom: 10px;
}
.demo .btn_play_icon {
  content: "";
  position: absolute;
  z-index: 10;
  margin-top: 6px;
  margin-left: -4px;
  border-top: 6px solid transparent;
  border-left: 12px solid #333;
  border-bottom: 6px solid transparent;
}
.demo .btn_favorite_icon {
  background-image: url(2913c9a0e727e1e2aac58ca3c0656354.png);
  background-position: -24px -2px;
  height: 14px;
  left: 50%;
  margin-left: -8px;
  margin-top: 5px;
  position: absolute;
  top: 50%;
  width: 16px;
}
.demo .btn_favorite.active .btn_favorite_icon {
  background-position: -24px -26px !important;
}
.demo .btn_stop_icon {
  position: absolute;
  border-left: 3px solid;
  border-right: 3px solid;
  height: 15px;
  width: 10px;
  left: 50%;
  top: 50%;
  margin-top: 5px;
  margin-left: -4px;
}
.demo .btn_comment_icon {
  position: absolute;
  width: 14px;
  height: 14px;
  background-image: url(b84e3c7c83643ba4ece23f9f0e4475f6.png);
  background-position: -21px -3px;
  top: 50%;
  left: 50%;
  margin-left: -7px;
  margin-top: 5px;
}
.demo .btn_favorite {
  bottom: 10px;
  right: 80px;
}
.demo .current .btn_comment_icon {
  background-position: -3px -3px !important;
}
.demo .current .btn_favorite {
  bottom: 0;
  right: 69px;
}
.demo .current .favorite_star {
  background-position: -1px -1px !important;
}
.demo .current .btn_favorite_icon {
  background-position: -4px -2px !important;
}
.demo .current .active .btn_favorite_icon {
  background-position: -4px -26px !important;
}
.demo .favorite_star {
  display: none;
  background-image: url(2913c9a0e727e1e2aac58ca3c0656354.png);
  background-position: -13px -1px;
  width: 13px;
  height: 9px;
  background-size: 74px 25px;
}
.demo .task.task_gray {
  border: 1px solid #e7f9f9;
  border-top: 1px solid #e7f9f9;
  border-bottom: 1px solid #e7f9f9;
  background: #e7f9f9;
}
.demo .task.task_green {
  border: 1px solid #d9ffeb;
  border-top: 1px solid #d9ffeb;
  border-bottom: 1px solid #d9ffeb;
  background: #d9ffeb;
}
.demo .task.task_purple {
  border: 1px solid #ffe6f6;
  border-top: 1px solid #ffe6f6;
  border-bottom: 1px solid #ffe6f6;
  background: #ffe6f6;
}
.demo .task.task_blue {
  border: 1px solid #d8dbfe;
  border-top: 1px solid #d8dbfe;
  border-bottom: 1px solid #d8dbfe;
  background: #d8dbfe;
}
.demo .task.current {
  border: 1px solid #d22b02;
  border-bottom: 1px solid #d22b02;
  background: #d22b02;
  color: #fff;
}
.demo .task.current .task_name span:hover, .demo .task.current .task_number span:hover, .demo .task.current .task_descript {
  color: #fff;
  opacity: 0.7;
}
.demo .task:not(.non_search):hover .btn_comment, .demo .task:not(.non_search):hover .btn_favorite, .demo .task:not(.non_search):hover .btn_play {
  display: block;
}
.demo .task.current:hover .btn_stop {
  display: block;
}
.demo .task.current:hover .btn_play {
  display: none;
}
.demo .task.current:hover .btn_comment {
  display: block;
}
.demo .task.current .time_large {
  display: block;
}
.demo .w70 {
  width: 70px;
}
.demo .ml70 {
  margin-left: 70px;
}
.demo .btn_show_client_box {
  right: 130px;
  position: absolute;
  top: 150px;
  z-index: 11;
}
@media (max-width: 767.98px) {
  .demo .btn_show_client_box {
    display: none;
  }
}
.demo .btn_light_gray {
  display: inline-block;
  width: 105px;
  height: 36px;
}
.demo .popup_time_task {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -100px 0 0 -200px;
  background: #444;
  width: 410px;
  height: 174px;
  z-index: 100000;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 44px 20px 20px 20px;
  color: #dbdddd;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  -webkit-box-shadow: 0 8px 30px rgba(0, 0, 0, 0.8);
          box-shadow: 0 8px 30px rgba(0, 0, 0, 0.8);
  /*border: 1px solid rgba(0,0,0,0.3);*/
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  visibility: hidden;
  font-family: "decimaNovaPro", sans-serif;
}
.demo .popup_time_task.visible {
  visibility: visible;
}
.demo .popup_time__button_wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}
.demo .popup_head {
  position: absolute;
  width: 410px;
  left: -1px;
  top: -1px;
  height: 23px;
  background: url(7c1c25177f91fdf79aae1ed14ac7823e.png) 0 0 no-repeat;
}
.demo .btn_light_gray {
  border-radius: 3px;
  color: #fff;
  display: inline-block;
  font-family: "decimaNovaPro", sans-serif;
  font-size: 16px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  text-decoration: none;
  width: 106px;
  cursor: pointer;
  -webkit-transition: 150ms;
  transition: 150ms;
  background-color: #7c7c7c;
}
.demo .footer_timer {
  font-size: 10px;
  color: #fff;
  line-height: 17px;
  padding-left: 3px;
}
.demo .btn_light_gray:hover {
  -webkit-transition: 150ms;
  transition: 150ms;
  background: #e32c1d;
  color: #fff;
}
.demo .task_list {
  overflow: auto;
  height: 432px;
  width: 425px;
  margin-top: 4px;
  border-radius: 1px;
}
.demo .js_client_frame .task_list {
  width: 293px;
}
.demo .task_list .task:first-child {
  margin-top: 0;
  padding-bottom: 10px;
}
.demo .current_category {
  border-radius: 3px;
  padding: 0 16px;
  font-size: 12px;
  color: #fff;
  background: #c01808;
  border-bottom: 1px solid #8b1d02;
  display: none;
  cursor: pointer;
  margin-top: 14px;
  font-family: "decimaNovaPro", sans-serif;
}
.demo .current_category:hover {
  background: #8b1d02;
}
.demo .current_category.active {
  border-radius: 3px;
}
.demo .list_category {
  position: relative;
  width: 409px;
  margin: 10px 0 -10px -15px;
  display: none;
}
.demo .list_category li {
  height: 50px;
  padding-left: 22px;
  background: #d22b02;
  border-top: 2px solid #444444;
  cursor: pointer;
  color: #fff;
  line-height: 50px;
  font-size: 16px;
  position: relative;
  border-radius: 2px;
}
.demo .list_category li:hover {
  background: #ae1507;
}
.demo .list_category li:last-child {
  border-radius: 0 0 2px 2px;
}
.demo .list_category li.current {
  display: none;
}
.demo .task.current .current_category {
  display: block;
}
.demo .task .box_btns {
  position: static;
}
.demo .task.current .box_btns {
  position: relative;
}
.demo .task.current .task_icons {
  fill: white;
}
.demo .task_icons {
  fill: rgba(0, 0, 0, 0.51);
}
.demo .task_list .jspPane {
  width: 407px !important;
}
.demo .js_client_frame .task_list .jspPane {
  width: 290px !important;
}
.demo .task_list .jspVerticalBar, .demo .task_list .jspTrack {
  background: none;
}
.demo .popup_edit_task__inner .jspVerticalBar, .demo .popup_edit_task__inner .jspTrack {
  background: none;
}
.demo .js_rm_login_cont .jspVerticalBar, .demo .js_rm_login_cont .jspTrack {
  background: none;
}
.demo .js_task_list .jspContainer {
  overflow: hidden;
  position: relative;
}
.demo .js_task_list .jspPane {
  position: absolute;
}
.demo .js_task_list .jspVerticalBar {
  /*background: none repeat scroll 0 0 #FF0000;*/
  height: 100%;
  position: absolute;
  right: 0px;
  top: 0;
  -webkit-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
}
.demo .js_task_list .jspVerticalBar *, .demo .jspHorizontalBar * {
  margin: 0;
  padding: 0;
}
.demo .js_task_list .jspDrag {
  background: #7c7c7c;
  cursor: pointer;
  left: 1px;
  position: relative;
  top: 0;
  width: 6px;
  border-radius: 1px;
}
.demo .js_rm_login_cont .jspContainer {
  overflow: hidden;
  position: relative;
}
.demo .js_rm_login_cont .jspPane {
  position: absolute;
}
.demo .js_client_frame .js_rm_login_cont .jspPane {
  width: 290px !important;
}
.demo .popup_edit_task__inner .jspVerticalBar,
.demo .js_rm_login_cont .jspVerticalBar {
  height: 100%;
  position: absolute;
  right: 0px;
  top: 0;
}
.demo .popup_edit_task__inner .jspContainer {
  width: 690px;
}
.demo .js_rm_login_cont .jspContainer {
  width: 430px;
}
.demo .popup_edit_task__inner .jspVerticalBar *, .demo .jspHorizontalBar *,
.demo .js_rm_login_cont .jspVerticalBar *, .demo .jspHorizontalBar * {
  margin: 0;
  padding: 0;
}
.demo .popup_edit_task__inner .jspDrag {
  background: #7c7c7c;
  cursor: pointer;
  left: 1px;
  position: relative;
  top: 0;
  width: 6px;
  border-radius: 1px;
}
.demo .js_rm_login_cont .jspDrag {
  background: #7c7c7c;
  cursor: pointer;
  left: 8px;
  position: relative;
  top: 0;
  width: 6px;
  border-radius: 1px;
}
.demo .btn_show_client_box {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.demo .btn_show_client_box.hide {
  visibility: hidden !important;
  opacity: 0 !important;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.demo .btn_show_client_box:hover {
  visibility: visible;
  opacity: 1;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.demo .rm_client_timer_box.hide:hover + .btn_show_client_box {
  visibility: visible;
  opacity: 1;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.demo .hover_area {
  position: absolute;
  width: 425px;
  height: 490px;
  right: 0px;
  background: url(71a50dbba44c78128b221b7df7bb51f1.png) repeat 0 0;
  z-index: 10;
}
@media (max-width: 767.98px) {
  .demo .hover_area {
    display: none;
  }
}
.demo .hover_area:hover ~ .btn_show_client_box {
  visibility: visible;
  opacity: 1;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.demo .hover_area:hover ~ .rm_client_timer_box.hide:after {
  visibility: visible;
  opacity: 0.5;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.demo .small_input {
  position: relative;
}
.demo .small_input input {
  background: #4d5357;
  height: 29px;
  width: 36px;
  border: none;
  border-bottom: 1px solid #7c7c7c;
  padding: 0 2px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 14px;
  color: #7d7d7d;
  text-align: center;
}
.demo .small_input input:focus {
  border-bottom: 1px solid #b4b4b4;
}
.demo .small_input:after {
  content: "";
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  background: url(71a50dbba44c78128b221b7df7bb51f1.png) repeat 0 0;
}
.demo .active .small_input:after {
  display: none;
}
.demo .active .small_input input {
  color: rgba(255, 255, 255, 0.85);
}
.demo .rm_timer_filter {
  position: relative;
  height: 340px;
  padding: 0 7px 0 5px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #444;
  z-index: 10;
}
.demo .rm_timer_filter .col_left {
  float: left;
  width: 47px;
  color: #cccccc;
  font-family: "decimaNovaPro", sans-serif;
  font-size: 16px;
  line-height: 14px;
  padding-top: 13px;
}
.demo .rm_timer_filter .col_right {
  float: right;
}
.demo .rm_timer_filter .select_field_box,
.demo .rm_login_cont .select_field_box {
  display: block;
  width: 308px;
  height: 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  color: #cccccc;
}
.demo .rm_timer_filter .select_field_box--settings,
.demo .rm_login_cont .select_field_box--settings {
  width: 379px;
}
.demo .js_client_frame .rm_timer_filter .select_field_box {
  width: 220px;
}
.demo .popup_edit_task .rm_timer_filter .select_field_box {
  width: 225px;
}
.demo .rm_timer_filter .select_filter,
.demo .rm_login_cont .select_filter {
  display: block;
  width: 99%;
  float: right;
  height: 40px;
  border: 1px solid #7c7c7c;
  border-radius: 3px;
  cursor: pointer;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 7px 10px 0;
  font-size: 14px;
  line-height: 20px;
  background-color: #4d5357;
  border-bottom: 1px solid #a7b1b8;
}
.demo .rm_timer_filter .select_filter:hover,
.demo .rm_login_cont .select_filter:hover {
  border-bottom: 2px solid #a7b1b8;
}
.demo .rm_timer_filter .select_filter--settings,
.demo .rm_login_cont .select_filter--settings {
  width: 279px;
  height: 35px;
  font-size: 16px;
  padding-left: 5px;
}
.demo .rm_timer_filter .select_filter:after,
.demo .rm_login_cont .select_filter:after {
  position: absolute;
  content: "";
  top: 17px;
  right: 8px;
  width: 0;
  height: 0;
  border-top: 5px solid #a7b1b8;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  -webkit-transition: 150ms;
  transition: 150ms;
}
.demo .rm_timer_filter .select_field_list,
.demo .rm_login_cont .select_field_list {
  top: -15px;
  position: absolute;
  left: 0;
  display: none;
  width: 43%;
  border: 1px solid #f8f5f5;
  padding: 5px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  z-index: 1;
  background-color: white;
  color: #212121;
}
.demo .rm_timer_filter .select_field_list--settings,
.demo .rm_login_cont .select_field_list--settings {
  top: 0;
  width: 74%;
  left: 100px;
}
.demo .rm_timer_filter .select_field_list li,
.demo .rm_login_cont .select_field_list li {
  height: 45px;
  line-height: 45px;
  font-size: 16px;
  padding: 0 10px;
  background: #fff;
  cursor: pointer;
}
.demo .rm_timer_filter .select_field_box.open .select_filter:after,
.demo .rm_login_cont .select_field_box.open .select_filter:after {
  border-top: 6px solid #cbcbcb;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  -webkit-transition: 150ms;
  transition: 150ms;
}
.demo .rm_timer_filter .select_field_box.open {
  /*color: #fff;*/
}
.demo .rm_timer_filter .select_field_box.open .select_filter,
.demo .rm_login_cont .select_field_box.open .select_filter {
  border-bottom: 2px solid #a7b1b8;
}
.demo .rm_timer_filter .select_field_box.open .select_field_list,
.demo .rm_login_cont .select_field_box.open .select_field_list {
  display: block;
  border-color: #cbcbcb;
  border-radius: 5px;
}
.demo .rm_timer_filter .select_field_list li:hover,
.demo .rm_login_cont .select_field_list li:hover {
  background: #f0f0f0;
  color: #212121;
}
.demo .filter_buttons {
  position: absolute;
  right: 0;
  padding-right: 5px;
}
.demo .btn_filter_large {
  font-family: "decimaNovaPro", sans-serif;
  display: block;
  text-align: center;
  text-transform: uppercase;
  height: 34px;
  width: 120px;
  line-height: 34px;
  font-size: 14px;
  color: #fff;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  letter-spacing: 0.5px;
  background: #7c7c7c;
  -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
}
.demo .btn_filter_large--reset {
  margin-left: 5px;
}
.demo .btn_filter_large:hover {
  background: #545454;
}
.demo .btn_filter_large:active {
  top: 1px;
}
.demo .btn_close_filter {
  right: 6px !important;
}
.demo .btn_basket {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  width: 9px;
  height: 10px;
  cursor: pointer;
  background: url(817f9fe35ded76f16cc7ee8578f192fe.png) -155px -42px no-repeat;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  opacity: 1;
}
.demo .btn_basket:hover {
  opacity: 0.7;
}
.demo .no_filter_result {
  color: #e0e8e0;
  font-size: 20px;
  position: absolute;
  top: 185px;
  left: 0;
  width: 100%;
  z-index: 10;
}
.demo .no_filter_result.top_small {
  top: 232px;
}
.demo .client_frame .btn_show_client_box, .demo .client_frame .hover_area {
  display: none;
}
.demo .client_frame .rm_client_timer_box {
  -webkit-box-shadow: none;
          box-shadow: none;
  left: 0;
  top: 0;
}
.demo .hr_settings {
  background-color: #5a5a5a;
  border: none;
  /*width: 357px;*/
  display: block;
  height: 1px;
  position: relative;
}
.demo .popup_edit_task {
  height: 510px;
  width: 690px;
  position: absolute;
  z-index: 99999;
  left: -50%;
  top: 83px;
  background: #444444;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 25px 5px 0 5px;
  color: #cccccc;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.2s ease-out 0s;
  transition: opacity 0.2s ease-out 0s;
  -webkit-box-shadow: 0 8px 30px rgba(0, 0, 0, 0.8);
          box-shadow: 0 8px 30px rgba(0, 0, 0, 0.8);
}
.demo .popup_edit_task__inner {
  height: 470px;
  width: 690px;
}
.demo .popup_edit_task .select_filter {
  height: 35px;
}
.demo .popup_edit_task__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
}
.demo .popup_edit_task__item-name {
  text-align: right;
  padding: 11px;
  width: 103px;
}
.demo .popup_edit_task__item-name .required {
  color: #e57373;
}
.demo .popup_edit_task__item-content .settings_textfield {
  width: 550px;
}
.demo .popup_edit_task__item-content textarea {
  resize: none;
}
.demo .popup_edit_task__item-content .calendar_icon {
  content: "";
  background-image: url(88255e97d2b934c1130eaa4965cbd23b.svg);
  width: 25px;
  height: 25px;
  position: absolute;
  background-size: contain;
  top: 3px;
  right: 10px;
  pointer-events: none;
}
.demo .popup_edit_task__item_wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.demo .popup_edit_task__item_wrap .settings_textfield {
  width: 230px;
}
.demo .head_edit_popup {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 100%;
}
.demo .popup_edit_task.rm_client_timer:after {
  content: none;
}
.demo .left_input_edit {
  width: 310px;
  float: left;
  height: auto;
  padding: 13px 0 0 15px;
}
.demo .popup_edit_task .rm_timer_filter {
  font-weight: bold;
}
.demo .popup_edit_task .rm_timer_filter .col_left {
  font-size: 16px;
}
.demo .popup_edit_task .select_field_box.js_select_filter_box.open .select_filter {
  border-bottom: 0;
  border-radius: 3px 3px 0 0;
}
.demo .popup_edit_task .select_field_box.js_select_filter_box .select_filter {
  font-size: 16px;
  color: #a7b1b8;
  font-weight: normal;
}
.demo .popup_edit_task .select_field_box.disabled {
  pointer-events: none;
}
.demo .popup_edit_task .select_field_box.disabled .select_filter {
  color: #6d7174;
  border-bottom: 1px dotted #a7b1b8;
  background-color: #4d5357;
}
.demo .right_input_edit.rm_timer_filter {
  width: 218px;
  float: left;
  font-weight: bold;
  height: auto;
  padding: 13px 0 0 15px;
}
.demo .right_input_edit.rm_timer_filter .select_filter {
  width: 84px;
  font-size: 12px;
  color: #ffffff;
}
.demo .right_input_edit.rm_timer_filter .select_field_box {
  width: 84px;
}
.demo .popup_edit_task .timer_log_field {
  width: 106px;
  height: 30px;
}
.demo .popup_edit_task .timer_log_field:after {
  content: none;
}
.demo .hrs_inp input,
.demo .popup_edit_task .timer_log_field.hrs_inp {
  width: 84px;
}
.demo .hours_input .settings_textfield {
  width: 200px;
}
.demo .w91 {
  width: 91px !important;
}
.demo .popup_edit_task .timer_log_field input {
  height: 30px;
  color: #ffffff;
  font-size: 14px;
  padding: 0 10px;
}
.demo .task_rating {
  height: 58px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-top: 1px solid #5a5a5a;
  border-bottom: 1px solid #5a5a5a;
  padding: 12px 0 0 15px;
  margin-top: 4px;
}
.demo .rating_inp input,
.demo .rating_inp {
  width: 157px;
}
.demo .mr34 {
  margin-right: 34px !important;
}
.demo .edit_textarea {
  height: 112px;
  background: #fff;
  resize: none;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #444444;
  font-size: 14px;
  font-family: "Arial", "Tahoma", sans-serif;
  padding: 10px;
  margin: 8px 0;
  border: none;
  border-radius: 3px;
}
.demo .text_edit .settings_textfield {
  width: 100%;
}
.demo .show_edit.popup_edit_task {
  opacity: 1;
  visibility: visible;
}
.demo .show_edit.popup_edit_task .task .task_descript {
  color: rgba(0, 0, 0, 0.4);
}
.demo .popup_edit_task .rm_timer_filter .select_field_list li {
  font-weight: normal;
}
.demo .popup_edit_task:before {
  content: "";
  position: absolute;
  top: 25px;
  height: 105px;
  width: 100%;
  z-index: 10;
}
.demo .rmc_head {
  text-align: center;
}
.demo .popup_edit_task .task {
  display: block !important;
}
.demo .popup_edit_task .non_search:after {
  content: none !important;
}
.demo .popup_edit_task .open .select_filter:after {
  border-bottom: 6px solid #8f8f8f;
  border-top: 0 !important;
}
.demo .popup_edit_task .select_field_list li.current {
  background-color: #ebebeb;
  color: #212121;
}
.demo .popup_edit_task .select_field_list {
  width: 100%;
}
.demo .popup_edit_task .work_complete_procent li.current {
  background: #5b5b5b url(2fa2ec10f545217af3fca9e7e632c9bd.png) 65px -63px no-repeat;
}
.demo .popup_edit_task input::-webkit-input-placeholder {
  color: #ffffff;
}
.demo .popup_edit_task input::-moz-placeholder {
  color: #fff;
  opacity: 1;
}
.demo #popupDesc, .demo #popupDesc2, .demo #popupDesc3, .demo #popupDesc4, .demo #popupDesc5, .demo #popupNote, .demo #popupNote2, .demo #popupNote3, .demo #popupNote4, .demo #popupNote5 {
  overflow-y: auto;
  scrollbar-color: #7c7c7c transparent;
  scrollbar-width: thin;
  /* Handle */
  /* Handle on hover */
}
.demo #popupDesc::-webkit-scrollbar, .demo #popupDesc2::-webkit-scrollbar, .demo #popupDesc3::-webkit-scrollbar, .demo #popupDesc4::-webkit-scrollbar, .demo #popupDesc5::-webkit-scrollbar, .demo #popupNote::-webkit-scrollbar, .demo #popupNote2::-webkit-scrollbar, .demo #popupNote3::-webkit-scrollbar, .demo #popupNote4::-webkit-scrollbar, .demo #popupNote5::-webkit-scrollbar {
  width: 6px;
  cursor: pointer;
}
.demo #popupDesc::-webkit-scrollbar-thumb, .demo #popupDesc2::-webkit-scrollbar-thumb, .demo #popupDesc3::-webkit-scrollbar-thumb, .demo #popupDesc4::-webkit-scrollbar-thumb, .demo #popupDesc5::-webkit-scrollbar-thumb, .demo #popupNote::-webkit-scrollbar-thumb, .demo #popupNote2::-webkit-scrollbar-thumb, .demo #popupNote3::-webkit-scrollbar-thumb, .demo #popupNote4::-webkit-scrollbar-thumb, .demo #popupNote5::-webkit-scrollbar-thumb {
  background: #7c7c7c;
  border-radius: 1px;
  cursor: pointer;
}
.demo #popupDesc::-webkit-scrollbar-thumb:hover, .demo #popupDesc2::-webkit-scrollbar-thumb:hover, .demo #popupDesc3::-webkit-scrollbar-thumb:hover, .demo #popupDesc4::-webkit-scrollbar-thumb:hover, .demo #popupDesc5::-webkit-scrollbar-thumb:hover, .demo #popupNote::-webkit-scrollbar-thumb:hover, .demo #popupNote2::-webkit-scrollbar-thumb:hover, .demo #popupNote3::-webkit-scrollbar-thumb:hover, .demo #popupNote4::-webkit-scrollbar-thumb:hover, .demo #popupNote5::-webkit-scrollbar-thumb:hover {
  background: #7c7c7c;
  cursor: pointer;
}
.demo .rm_client_timer_login .btn_hide_client_bg {
  left: 0px;
  top: -1px;
}
.demo .rm_client_timer_login .btn_hide_client {
  left: 7px;
  top: 4px;
}
.demo .task .time_small_popup {
  position: absolute;
  right: 10px;
  top: 18px;
  font-size: 11px;
}
.demo .popup_edit_task:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.demo .popup_open .popup_edit_task:after {
  opacity: 0.2;
  visibility: visible;
}
.demo .task_rating {
  height: 0px;
  border-top: 0;
  border-bottom: 1px solid #5a5a5a;
  padding: 1px 0 0 15px;
}
.demo .task_rating .clearfix.mb10 {
  display: none;
}
.demo .ui-datepicker-calendar:hover .btn_play {
  display: block !important;
}
.demo .btn_show_client_box .btn_main {
  width: 155px;
  height: 50px;
  line-height: 1;
  font-size: 16px;
}
.demo .btn_show_client_box .btn_main:active {
  top: 3px;
}
.demo .wrapSettings {
  padding-left: 7px;
  font-family: "decimaNovaPro", sans-serif;
}
.demo .wrapSettings--checkbox {
  margin-top: 40px;
}
.demo .edit_description {
  display: none;
}
.demo .edit_description.active {
  display: block;
}
.demo .show_description {
  color: #2a94c9;
  padding-top: 11px;
  height: 30px;
  cursor: pointer;
}
.demo .show_description:hover {
  border-bottom: 1px solid #2a94c9;
}
.demo .show_description.hide {
  display: none;
}

.sir-trevor_demo {
  margin-top: 20px;
}
@media (max-width: 991.98px) {
  .sir-trevor_demo {
    height: unset;
    pointer-events: none;
  }
}
.sir-trevor_demo_wrap {
  margin-bottom: 40px;
  display: none;
}
@media (min-width: 768px) {
  .sir-trevor_demo_wrap {
    display: block;
  }
}
.sir-trevor_demo .rm_client_timer_box {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.sir-trevor_demo .rm_client_timer_box:after {
  content: none;
}
.sir-trevor_demo .draggable_block {
  z-index: 100;
}
.sir-trevor_demo .rm_client_timer_box.hide .rm_client_timer_login {
  z-index: 110;
}
.sir-trevor_demo .btn_hide_client.js_btn_hide_client {
  z-index: 111;
}
.sir-trevor_demo .popup_edit_task {
  left: 0%;
}

.profile_content__item {
  list-style: none;
}
.profile_content__item:not(:last-of-type) {
  margin-bottom: 17px;
}
.profile_content__link {
  display: inline-block;
  font-weight: 100;
  font-size: 20px;
  line-height: 23px;
  color: #323343;
  background-color: transparent;
  padding: 0;
  border: none;
  cursor: pointer;
  text-align: left;
}
.profile_content__link:hover {
  color: #ef4115;
}
.profile_content__link.active {
  pointer-events: none;
  /* делаем элемент неактивным для взаимодействия */
  cursor: default;
  color: #ef4115;
}
.profile_content__tabs {
  margin-top: 25px;
}
@media (min-width: 768px) {
  .profile_content__tabs {
    margin-top: 0;
  }
}
@media (min-width: 768px) {
  .profile_content__form_wrapper {
    margin-top: -3px;
    max-width: 76%;
  }
}
@media (min-width: 992px) {
  .profile_content__form_wrapper {
    max-width: 76.9%;
  }
}
@media (min-width: 1200px) {
  .profile_content__form_wrapper {
    min-height: 600px;
    max-width: 74.8%;
  }
}
.profile_content .profile_content__block_title {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 400;
  color: #323343;
}
@media (min-width: 768px) {
  .profile_content .profile_content__block_title {
    font-size: 28px;
  }
}
.profile_content__form {
  position: relative;
  padding-bottom: 40px;
}
.profile_content__form:last-child {
  padding-bottom: 65px;
}
.profile_content__form .lint_to_edit_pass {
  border-bottom: 1px dashed #323343;
  position: absolute;
  bottom: 42px;
  left: 150px;
  -webkit-transition: 100ms linear;
  transition: 100ms linear;
  font-size: 18px;
  line-height: 23px;
  color: #323343;
}
@media (min-width: 1200px) {
  .profile_content__form .lint_to_edit_pass:hover {
    cursor: pointer;
    border-color: transparent;
  }
}
.profile_content .form_checkbox {
  margin-top: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}
.profile_content #form_edit_pass .button {
  margin-top: 6px;
}
.profile_content #lic {
  min-height: 395px;
}

.license_content {
  margin-top: 40px;
}
@media (min-width: 768px) {
  .license_content {
    margin-top: 0;
  }
}
.license_content__header {
  font-size: 18px;
  font-weight: 100;
  line-height: 26px;
}
.license_content__form {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .license_content__form {
    margin-bottom: 20px;
  }
}
.license_content #bind_email {
  margin-top: 15px;
}
@media (min-width: 768px) {
  .license_content #bind_email {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
@media (min-width: 768px) {
  .license_content #bind_email .form_input {
    -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
  }
}
@media (min-width: 768px) {
  .license_content #bind_email .button {
    margin-top: 19px;
    margin-left: 30px;
  }
}
.license_content .bind_license__status {
  margin-top: 20px;
}
.license_content .bind_license__status_email {
  font-weight: 400;
  font-size: 18px;
}
.license_content .bind_license__status_warn {
  font-weight: 100;
}
.license_content__status + .license_content__status {
  margin-top: 10px;
}
.license_content__status_email_remove {
  color: #ff4b1e;
  -webkit-transition: 200ms linear;
  transition: 200ms linear;
}
@media (min-width: 1200px) {
  .license_content__status_email_remove:hover {
    color: #be2f0c;
  }
}
.license_content .custom_table {
  margin-bottom: 55px;
}
.license_content__ico {
  width: 15px;
  height: 15px;
  margin-left: 5px;
}
.license_content__ico.copy {
  position: relative;
  top: 2px;
}
.license_content__ico.copy:hover {
  cursor: pointer;
}
.license_content .check path {
  fill: none;
  stroke: #47B857;
  stroke-miterlimit: 10;
  stroke-width: 10px;
  margin: 40px;
  stroke-dasharray: 500;
  stroke-dashoffset: 500;
  -webkit-animation: draw 0.5s linear forwards;
          animation: draw 0.5s linear forwards;
}
@-webkit-keyframes draw {
  from {
    stroke-linecap: round;
  }
  to {
    stroke-linecap: round;
    stroke-dashoffset: 0;
  }
}
@keyframes draw {
  from {
    stroke-linecap: round;
  }
  to {
    stroke-linecap: round;
    stroke-dashoffset: 0;
  }
}
.license_content .content_with_icon .copy {
  display: inline-block;
}
.license_content .content_with_icon .copied-ico {
  display: none;
}
.license_content .content_with_icon.copied .copy {
  display: none;
}
.license_content .content_with_icon.copied .copied-ico {
  display: inline-block;
}

.custom_table {
  margin-bottom: 20px;
  margin-left: -15px;
  margin-right: -15px;
}
@media (min-width: 992px) {
  .custom_table {
    margin-bottom: 30px;
    margin-left: 0;
    margin-right: 0;
  }
}
.custom_table__title {
  margin-top: 35px;
  margin-bottom: 12px;
  padding-left: 15px;
  font-size: 21px;
  line-height: 25px;
  font-weight: 500;
  text-transform: capitalize;
  color: #323343;
}
@media (min-width: 992px) {
  .custom_table__title {
    padding: 0;
  }
}
.custom_table table {
  width: 100%;
  table-layout: fixed;
}
.custom_table table th,
.custom_table table td {
  padding: 10px 15px;
  font-size: 14px;
  border-top: none;
}
@media (min-width: 768px) {
  .custom_table table th,
.custom_table table td {
    width: 100%;
  }
}
.custom_table table thead tr {
  display: none;
}
@media (min-width: 768px) {
  .custom_table table tbody {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
@media (min-width: 992px) {
  .custom_table table tbody {
    display: table-row-group;
  }
}
.custom_table table tbody tr {
  padding-top: 15px;
  padding-bottom: 15px;
  display: block;
}
@media (min-width: 768px) {
  .custom_table table tbody tr {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
  }
}
.custom_table table tbody tr + tr {
  margin-top: 40px;
}
@media (min-width: 768px) {
  .custom_table table tbody tr + tr {
    margin-top: 0;
  }
}
.custom_table table tbody td {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  position: relative;
  border: none;
}
.custom_table table tbody td::before {
  content: attr(data-th);
  margin-bottom: 5px;
  font-size: 16px;
  border-bottom: 1px solid #dadada;
  line-height: 19px;
  font-weight: 500;
}
@media (min-width: 992px) {
  .custom_table table {
    margin-bottom: 10px;
  }
  .custom_table table th,
.custom_table table td {
    padding: 8px 20px 8px 0;
    font-weight: 300;
    vertical-align: middle;
    border: none;
  }
  .custom_table table thead tr {
    display: table-row;
  }
  .custom_table table thead th {
    text-align: left;
    border-bottom: 1px solid #dadada;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    color: #323343;
  }
  .custom_table table tbody td {
    display: table-cell;
    border-bottom: 1px solid #dadada;
  }
  .custom_table table tbody tr {
    padding: 0;
    display: table-row;
  }
  .custom_table table tbody tr + tr {
    margin-top: 0;
  }
  .custom_table table tbody td::before {
    content: none;
  }
  .custom_table table tbody tr:last-child td {
    border: none;
  }
}
@media (min-width: 992px) {
  .custom_table .custom_table__td {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
  .custom_table .custom_table__td--small {
    width: 5em;
  }
  .custom_table .custom_table__td--email {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
  .custom_table .custom_table__td--date {
    width: 6em;
  }
}

.recaptcha {
  margin-right: 10px;
}
.recaptcha__error {
  font-size: 18px;
  display: none;
  color: #ef4115;
}
.recaptcha__error_title {
  margin-right: 10px;
}
.recaptcha__error.show {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.draggable_client {
  position: absolute;
  width: 0;
  top: 237px;
  height: 1px;
  opacity: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  left: 50%;
  z-index: 1;
  -webkit-transition: 50ms;
  transition: 50ms;
}
@media (min-width: 768px) {
  .draggable_client {
    top: 77px;
  }
}
.draggable_client.overflow {
  opacity: 1;
  width: 100%;
}
.draggable_client_container {
  position: relative;
  height: 398px;
  overflow: hidden;
  width: 50%;
  margin-left: auto;
}
@media (max-width: 767.98px) {
  .draggable_client_container {
    width: 100%;
    margin-left: unset;
    text-align: initial;
  }
}
.draggable_client_container.active {
  opacity: 1 !important;
  overflow: visible !important;
}
.draggable_client .demo {
  position: absolute;
  top: 30px;
  right: 0;
}
@media (max-width: 991.98px) {
  .draggable_client .demo {
    -webkit-transform: scale(0.85);
            transform: scale(0.85);
    right: -25px;
  }
}
@media (max-width: 767.98px) {
  .draggable_client .demo {
    right: unset;
    -webkit-transform: scale(0.65);
            transform: scale(0.65);
  }
}

.draggable_client.show_demo {
  opacity: 1;
  visibility: visible;
  width: 100%;
}

@media (max-width: 767.98px) {
  .js--client-wrapper {
    -webkit-transform: scale(0.69);
            transform: scale(0.69);
    position: relative;
    margin-top: 50px;
    text-align: initial;
  }
  .js--client-wrapper .demo {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    margin-left: auto;
    margin-right: auto;
  }
}

.button_demo_client .draggable_client_container {
  height: 0;
}

.contact_us_form {
  margin-bottom: 30px;
}
.contact_us_form .form_input {
  max-width: 500px;
}

.cookie_info {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #FFFFFF;
  -webkit-box-shadow: 0 -2px 10px rgba(108, 108, 140, 0.22);
          box-shadow: 0 -2px 10px rgba(108, 108, 140, 0.22);
  z-index: 10;
}
.cookie_info__row {
  height: 64px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 991.98px) {
  .cookie_info__row {
    height: 98px;
  }
}
.cookie_info__description {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #909090;
  font-size: 16px;
  line-height: 1.19;
}
@media (max-width: 991.98px) {
  .cookie_info__description {
    font-size: 14px;
    line-height: 1.2;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 88%;
            flex: 0 0 88%;
    max-width: 88%;
  }
}
.cookie_info__icon {
  margin-right: 11px;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 18px;
          flex: 0 0 18px;
  height: 40px;
}
.cookie_info__close_button {
  margin-left: 13px;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
@media (max-width: 1199.98px) {
  .cookie_info__close_button {
    margin-left: 8px;
  }
}
.cookie_info__close-icon {
  display: block;
  width: 14px;
  height: 14px;
}
.cookie_info__bold_text {
  font-weight: 600;
  text-decoration: underline;
}

.contact_card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 31px 20px;
  color: #ef4115;
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  text-decoration: none;
  border-radius: 10px;
  border: 2px solid transparent;
  -webkit-box-shadow: 0 -2px 10px rgba(108, 108, 140, 0.22);
          box-shadow: 0 -2px 10px rgba(108, 108, 140, 0.22);
  -webkit-transition: border-color 250ms;
  transition: border-color 250ms;
}
.contact_card:hover {
  border-color: #ef4115;
}
.contact_card__icon {
  display: block;
  width: 24px;
  height: auto;
  margin-bottom: 15px;
  stroke: #ef4115;
  stroke-width: 2px;
}

.articles .empty {
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  color: #333;
}
.articles__cards_row {
  padding: 0 0 20px;
  list-style-type: none;
}
@media (min-width: 1200px) {
  .articles__cards_row {
    padding: 0;
  }
}
@media (max-width: 767.98px) {
  .articles__cards_row li:not(:last-child) {
    margin-bottom: 38px;
  }
}
@media (min-width: 768px) {
  .articles__cards_row li {
    margin-bottom: 60px;
  }
}
@media (min-width: 1200px) {
  .articles__cards_row li {
    margin-bottom: 45px;
  }
}
.articles__cards_row li:not(:nth-child(-n+2)) {
  display: none;
}
@media (min-width: 768px) {
  .articles__cards_row li:not(:nth-child(-n+2)) {
    display: block;
  }
}
.articles__cards_row--blog_page li:not(:nth-child(-n+2)) {
  display: block;
}

.article_card {
  color: inherit;
}
.article_card__wrap:hover .article_card__photo img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.article_card:hover {
  color: inherit;
  text-decoration: none;
}
.article_card__photo {
  margin-bottom: 10px;
  overflow: hidden;
}
@media (min-width: 1200px) {
  .article_card__photo {
    margin-bottom: 8px;
  }
}
.article_card__photo img {
  -webkit-transition: all 250ms;
  transition: all 250ms;
  width: 100%;
  height: auto;
}
.article_card__title {
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.55px;
  line-height: 1.2;
  color: #292929;
  -webkit-transition: 150ms;
  transition: 150ms;
  margin-bottom: 5px;
}
.article_card__title:hover {
  color: #ff4213;
  -webkit-transition: 150ms;
  transition: 150ms;
}
@media (min-width: 768px) {
  .article_card__title {
    font-size: 16px;
  }
}
@media (min-width: 1200px) {
  .article_card__title {
    font-size: 18px;
  }
}
.article_card .article_short_text {
  font-size: 14px;
}

.article_information {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  margin-bottom: 5px;
}
.article_information--bottom {
  border-top: 1px solid #ccc;
  padding-top: 5px;
  margin-top: 5px;
}
.article_information__category {
  font-weight: 700;
  color: #ef4115;
}
.article_information__date {
  color: #557;
  font-weight: 700;
  font-size: 14px;
}
.article_information__author {
  color: #557;
  font-weight: 700;
  font-size: 14px;
}

.page-top-description {
  margin-bottom: 40px;
}

.investor_relations__block3 {
  margin-bottom: 40px;
}
.investor_relations__block3_content {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
}
@media (min-width: 768px) {
  .investor_relations__block3_content {
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }
}
.investor_relations__block3_content h1, .investor_relations__block3_content h2, .investor_relations__block3_content h3, .investor_relations__block3_content h4, .investor_relations__block3_content h5, .investor_relations__block3_content h6 {
  margin-top: 0;
}

.investor_relations__block2 {
  margin-bottom: 40px;
}
.investor_relations__block2_content {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
}
@media (min-width: 768px) {
  .investor_relations__block2_content {
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }
}
.investor_relations__block2_content h1, .investor_relations__block2_content h2, .investor_relations__block2_content h3, .investor_relations__block2_content h4, .investor_relations__block2_content h5, .investor_relations__block2_content h6 {
  margin-top: 0;
}

.achievements {
  margin-bottom: 40px;
  padding: 20px 0;
}
.achievements__sir-trevor {
  padding: 0;
  margin-top: 0;
  margin-bottom: 40px;
}
.achievements__item {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  position: relative;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .achievements__item {
    margin-bottom: 0;
  }
  .achievements__item:after {
    content: "";
    position: absolute;
    height: 85%;
    right: 0;
    width: 1px;
    background-color: #e6e6e6;
    -ms-flex-item-align: center;
        align-self: center;
  }
  .achievements__item:last-child:after {
    content: none;
  }
}
.achievements__title {
  font-weight: 600;
}

.grecaptcha-badge {
  display: none;
}